import React, { useMemo, useState } from 'react';
import Card from 'components/card';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { useDisclosure } from '@chakra-ui/hooks';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import InputField from './InputField';
import api from 'api/api';
import Toast from 'components/toast';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

const CheckTable = (props) => {
  const { columnsData, tableData, totalPages, currentPage, onPageChange, fetchCustomers } =
    props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [serivceName, setServiceName] = useState('');
  const [serivceCost, setServiceCost] = useState('');
  const [serivceId, setServiceId] = useState('');
  const [modalTitle, setModalTittle] = useState('');

  const [otherInformation, setOtherInformation] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 100;



  // ===============================================   MANIPULADORES   ========================================================
  // --------------------------------------------------------------------------------------------------------------------------
  const handleEditOtherInformation = (idField, valueField) => {

    const otherInformationEdited = otherInformation.map(dadosCampo => {
      if (dadosCampo.id === idField) {
        return { ...dadosCampo, valueField: valueField }; // Cria um novo dadosCampo com o valor alterado
      }
      return dadosCampo; // Retorna o dadosCampo original se não for o que estamos procurando
    });

    setOtherInformation(otherInformationEdited);

  }// Fim Func handleEditOtherInformation




  // --------------------------------------------------------------------------------------------------------------------------
  const handleAddNewInformation = () => {

    console.log("otherInformation:", otherInformation);

    //onClose(); // Fecha o modal anterior

    Swal.fire({
      title: 'Informe o nome do campo',
      html: `
        <input type="text" id="field-name" class="swal2-input" placeholder="Digite o nome do campo" />
      `,
      showCancelButton: true,
      confirmButtonText: 'Adicionar',
      cancelButtonText: 'Cancelar',
      focusConfirm: false, // Desativa o foco automático no botão de confirmação
      preConfirm: () => {
        const inputValue = document.getElementById('field-name').value;
        if (!inputValue) {
          Swal.showValidationMessage('O nome do campo é obrigatório!');
        }
        return inputValue;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setOtherInformation([
          ...otherInformation,
          {
            id: uuidv4(),
            nameField: result.value,  // Nome do campo inserido pelo usuário
            valueField: '',
          },
        ]);

        /*Swal.fire({
          icon: 'success',
          title: 'Campo adicionado!',
          text: `Campo "${result.value}" foi adicionado.`,
        });*/
      }
    });

  }// Fim Func handleAddNewInformation



  // --------------------------------------------------------------------------------------------------------------------------
  // Remover campo existente
  const handleRemoveInformation = (id) => {
    const filteredInformation = otherInformation.filter((field) => field.id !== id);
    setOtherInformation(filteredInformation);
  };







  // ==========================================================================================================================

  const openEditForm = (value) => {
    console.log('value:', value);

    setModalTittle('Editar');
    setServiceCost(value.contact);
    setServiceName(value.name);
    setServiceId(value.id);
    setOtherInformation(value.fields);
    onOpen();
  };

  const submitForm = () => {
    setIsLoading(true);

    console.log('Nome:', serivceName, 'Contato: ', serivceCost);

    api
      .post('leads', {

        name: serivceName,
        contact: parseFloat(serivceCost),
        fields: otherInformation,
      })
      .then((response) => {
        console.log('response.data: ', response.data);

        Toast.fire({
          icon: 'success',
          title: 'Lead adicionado com sucesso!',
        });
        onClose();
        fetchCustomers(currentPage);
        /*setTimeout(() => {
          window.location.reload();
        }, 1500);*/

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro ao enviar dados para criação de Lead:', error);
        Toast.fire({
          icon: 'error',
          title: 'Erro ao enviar dados para criação de Lead, tente novamente mais tarde.',
        });
      });
  };

  const editService = () => {
    setIsLoading(true);

    console.log('leadId', serivceId);

    api
      .patch('leads/' + serivceId, {
        name: serivceName,
        contact: serivceCost,
        fields: otherInformation,
      })
      .then((response) => {
        console.log(response.data);

        Toast.fire({
          icon: 'success',
          title: 'Seu lead foi editado com sucesso!',
        });
        onClose();
        fetchCustomers(currentPage);
        /*setTimeout(() => {
          window.location.reload();
        }, 1500);*/

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro ao editar lead:', error);
        Toast.fire({
          icon: 'error',
          title: 'Erro ao editar serviço, tente novamente mais tarde.',
        });
      });
  };
  const confirmDeleteService = (id) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Não será possivel reverter essa exclusão!',
      icon: 'warning',
      position: 'top',
      showCancelButton: true,
      confirmButtonColor: '#422afb',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      customClass: 'dark:bg-[#00205a] dark:text-[#eaf2f9]',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteService(id);
      }
    });
  };

  const deleteService = (id) => {
    setIsLoading(true);
    api
      .delete('leads/' + id)
      .then((response) => {
        console.log(response.data);

        Toast.fire({
          icon: 'success',
          title: 'O lead foi deletado com sucesso!',
        });

        fetchCustomers(currentPage);
        /*setTimeout(() => {
          window.location.reload();
        }, 1500);*/

        setIsLoading(false);
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: 'Erro ao excluir lead, tente novamente mais tarde.',
        });
      });
  };

  return (
    <Card extra={'w-full h-full sm:overflow-auto px-6 mt-1'}>
      <header className="relative flex items-center justify-between pt-4">
        <button
          onClick={() => {
            setModalTittle('Adicionar');
            setServiceCost('');
            setServiceName('');
            //setServiceId(value.id);
            setOtherInformation([]);
            onOpen();
          }}
          title="Adicionar lead"
          className="flex items-center justify-center rounded-full bg-brand-500 p-2 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          <MdAdd />
        </button>
      </header>
      <Modal isOpen={isOpen} onClose={onClose} trapFocus={false} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max _!max-w-[85%] top-[17vh] md:top-[17vh] md:min-w-[700px]">
          <ModalBody>
            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[700px] flex !z-[1004]">
              <h1 className="mb-[20px] text-2xl font-bold">
                {modalTitle} Lead
              </h1>
              <div className="grid gap-4 grid-cols-1  md:grid-cols-2">
                <InputField
                  label="Nome"
                  placeholder="Ex: Jorge"
                  id="service"
                  type="text"
                  extra="w-full"
                  value={serivceName}
                  onChange={(e) => setServiceName(e.target.value)}
                />
                <InputField
                  label="Contato"
                  placeholder="Ex: 11 98888-8888"
                  id="cost"
                  type="number" //number
                  extra="w-full"
                  value={serivceCost}
                  onChange={(e) => setServiceCost(e.target.value)}
                />

              </div>


              {/* =========================================  OUTROS CAMPOS ========================================= */}
              <div className="mt-10 text-center">
                <span>Outras informações</span>
              </div>

              {/* -----------------------------------------  ----------------------------------------- */}
              {
                otherInformation.length > 0 ?
                  <div className="grid gap-4 grid-cols-1 p-3  rounded-xl border border-gray-200 dark:!border-white/10  md:grid-cols-2">


                    {/* --------------------------------------  CAMPOS DINAMINCOS  --------------------------------- */}
                    {

                      otherInformation.map((dadosCampo, index) => (

                        <div key={index} >
                          <div className='flex'>
                            <label
                              //htmlFor={id}
                              className={`text-sm text-navy-700 dark:text-white font-bold`}
                            >
                              {dadosCampo.nameField}
                            </label>

                            <button
                              onClick={() => handleRemoveInformation(dadosCampo.id)}
                              className="text-[20px] ml-1 text-white text-gray-500 hover:cursor-pointer dark:text-gray-300 ampliar"
                              title='Excluir campo'
                            >
                              <MdDelete />
                            </button>
                          </div>

                          <input
                            type='text'
                            id={dadosCampo.id}
                            placeholder="Insira uma informação..."
                            className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white`}
                            onChange={(e) => handleEditOtherInformation(dadosCampo.id, e.target.value)}
                            value={dadosCampo.valueField}
                          />
                        </div>
                      ))
                    }


                  </div>

                  :

                  // ------------------------   NENHUMA INFORMAÇÃO   ------------------------
                  <div className='text-center mt-5'>
                    <span>
                      <i>Nenhuma ...</i>
                    </span>
                  </div>
              }



              {/* =========================================  BOTÕES  ========================================= */}
              <div className="mt-10 flex justify-end gap-2">
                <button
                  onClick={handleAddNewInformation}
                  className="linear rounded-xl px-5 py-3 text-base font-medium text-gray-800 transition duration-200 bg-gray-200 hover:bg-gray-300 active:bg-brand-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Adicionar campo
                </button>
                <button
                  onClick={onClose}
                  className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                >
                  Cancelar
                </button>
                <button
                  onClick={() =>
                    modalTitle === 'Adicionar' ? submitForm() : editService()
                  }
                  className="linear rounded-xl px-5 py-3 text-base font-medium text-green-500 transition duration-200  border-2 border-green-500 hover:bg-green-600/5 active:bg-green-700/5 dark:border-green-400 dark:bg-green-400/10 dark:text-white dark:hover:bg-green-300/10 dark:active:bg-green-200/10"
                >
                  Salvar
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'Contato') {
                      data = (
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } /*else if (cell.column.Header === 'Interesses') {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    }*/ else if (cell.column.Header === 'Nome') {
                      data = (
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'Ação') {
                      data = (
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() => openEditForm(row.original)}
                            className="flex items-center justify-center rounded-xl bg-brand-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                          >
                            <MdEdit />
                          </button>
                          <button
                            onClick={() => {
                              confirmDeleteService(row.original.id);
                            }}
                            className="flex items-center justify-center rounded-xl bg-red-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-red-700 active:bg-brand-700 dark:bg-red-500 dark:text-white dark:hover:bg-red-700 dark:active:bg-red-200"
                          >
                            <MdDelete />
                          </button>
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pt-[14px] pb-[16px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="flex justify-center mt-4 mb-4">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-gray-400 px-4 py-2 rounded-l-xl disabled:bg-gray-200  dark:bg-gray-700 dark:disabled:bg-gray-600"
          >
            Anterior
          </button>
          <h2 className="p-2 px-4" id="page">
            {currentPage}
          </h2>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="bg-gray-400 px-4 py-2 rounded-r-xl disabled:bg-gray-200  dark:bg-gray-700 dark:disabled:bg-gray-600"
          >
            Próximo
          </button>
        </div>
      </div>
    </Card>
  );
};

export default CheckTable;
