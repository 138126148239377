// Custom components
import { useState } from 'react';
import InputField from 'components/fields/InputField';

const Form = ({
  urlTypebot,
}) => {



  /* =========================================================================================================================== */
  return (
    <div className="text-base text-navy-700 mt-10 dark:text-white  md:mt-0">


      {/* ============================== DADOS CONFIGURAÇÕES ============================== */}
      <div className="grid grid-cols-2 gap-5 mt-10">

        <InputField
          label="Nome"
          id="user.name"
          type="text"
          extra="w-full"
          //value={customerName}
          //onChange={(e) => setCustomerName(e.target.value)}
          placeholder="Nome..."
        />

        <InputField
          label="E-mail"
          id="user.email"
          type="email"
          extra="w-full"
          //value={customerName}
          //onChange={(e) => setCustomerName(e.target.value)}
          placeholder="email@dominio.com..."
        />
      </div>

      <div className="grid grid-cols-2 gap-5 mt-10">

        <InputField
          label="Senha"
          id="user.pass"
          type="password"
          extra="w-full"
          //value={customerName}
          //onChange={(e) => setCustomerName(e.target.value)}
          placeholder="Digite uma senha..."
        />

        <InputField
          label="Confirmar senha"
          id="user.passConfirm"
          type="password"
          extra="w-full"
          //value={customerName}
          //onChange={(e) => setCustomerName(e.target.value)}
          placeholder="Repita a senha..."
        />
      </div>

      <div className="grid grid-cols-1 gap-5 mt-10">

        <InputField
          label="Endereço"
          id="user.address"
          type="text"
          extra="w-full"
          //value={customerName}
          //onChange={(e) => setCustomerName(e.target.value)}
          placeholder="Digite o endereço..."
        />
      </div>

      <div className="grid grid-cols-2 gap-5 mt-10">

        <InputField
          label="Nome empresa"
          id="user.companyName"
          type="text"
          extra="w-full"
          //value={customerName}
          //onChange={(e) => setCustomerName(e.target.value)}
          placeholder="Digite o nome da empresa..."
        />

        <InputField
          label="WhatsApp"
          id="user.whatsApp"
          type="text"
          extra="w-full"
          //value={customerName}
          //onChange={(e) => setCustomerName(e.target.value)}
          placeholder="11 98888-8888..."
        />
      </div>


    </div>
  );
}

export default Form;
