import React, { useMemo } from 'react';
import CardMenu from 'components/card/CardMenu';
import Checkbox from 'components/checkbox';
import Card from 'components/card';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

const CheckTable = (props) => {
  const { columnsData, tableData, cabeçalo } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  //console.log('data: ', data);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: Number.MAX_SAFE_INTEGER,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  };

  return (
    <Card extra={'w-full h-full mt-5 sm:overflow-auto px-6'}>
      <header className="relative flex items-center justify-between pt-4 ">
        <div className="text-xl text-center font-bold text-navy-700 mx-auto dark:text-white">
          {cabeçalo}
        </div>
      </header>

      <div
        //className="mt-8 _overflow-x-scroll _xl:overflow-x-hidden"
        className={`smallScrollbar mt-8 overflow-x-scroll xl:overflow-x-hidden` /* ${carregando ? 'hidden' : ''} */}
        style={{ maxHeight: '630px', overflow: 'auto' }}
      >
        <table
          {...getTableProps()}
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead
            className='bg-white dark:!bg-navy-800 z-10 sticky top-0'
          >
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] _pr-16 _text-start text-center dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'Nome') {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {' '}
                          {cell.value}{' '}
                        </p>
                      );
                    } else if (cell.column.Header === 'Fatura') {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value === 'payed' ? (
                              <div className="rounded-lg text-green-500 px-2 py-1 text-xs font-bold uppercase _text-white transition duration-200 dark:text-green-700">
                                Pago
                              </div>
                            ) : (
                              <div className="rounded-lg text-orange-500 px-2 py-1 text-xs font-bold uppercase _text-white transition duration-200 dark:text-orange-400">
                                Pendente
                              </div>
                            )}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'Tempo após vencimento') {
                      data = (
                        <p className={`text-sm font-bold text-navy-700 dark:text-white ${cell.value == 'mais de 1 ano...' ? 'italic' : ''}`}>
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === 'WhatsApp') {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pt-[14px] _pb-[16px] text-center sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default CheckTable;
