/* eslint-disable */

import { useState, useEffect, useRef } from 'react';
import { HiX } from 'react-icons/hi';
import Links from './components/Links';

import routes from '../../routes.js';

const Sidebar = ({ open, onClose }) => {

  const ref = useRef();

  useEffect(() => {
    function handleClickOutside(event) {

      // PEGA OS ESTILOS DO BOTÃO CLOSE DA SIDEBAR E DEFINE SE ELE ESTÁ VISÍVEL
      const btnCloseSidebar = document.querySelector('#btnCloseSidebar');
      const style_btnCloseSidebar = btnCloseSidebar ? window.getComputedStyle(btnCloseSidebar) : false;
      const isVisible_BtnCloseSidebar = style_btnCloseSidebar && style_btnCloseSidebar?.display !== 'none' && style_btnCloseSidebar?.visibility !== 'hidden' && style_btnCloseSidebar?.opacity !== '0';
      //console.log('isVisible_BtnCloseSidebar: ', isVisible_BtnCloseSidebar);

      if (ref.current && !ref?.current?.contains(event.target) && isVisible_BtnCloseSidebar) {
        //document.querySelector('#sidebar_id').classList.add('-translate-x-96', 'ml-[-96px]');
        onClose();
      }
    }

    // Certifique-se de que o evento é removido se o componente for desmontado
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!open) {
    return null;
  }

  return (
    <div
      ref={ref}
      id='sidebar_id'
      className={`sm:none linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all duration-1000 dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 sideBar ${
        open ? 'translate-x-0' : '-translate-x-96 ml-[-96px]'
      }`} //xl:!z-0
    >
      {/* BOTÃO CLOSE DA SIDEBAR */}
      <span
        id='btnCloseSidebar'
        className="absolute top-4 right-4 block cursor-pointer navBar" //xl:hidden
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-center`}>
        <div className="mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          Click<span class="font-medium">Digital</span>
        </div>
      </div>
      <div class="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />


      {/* Nav item */}

      <ul className="mb-auto pt-1" /*onClick={onClose}*/>
        <Links 
          routes={routes} 
          onClose={onClose}
        />
      </ul>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
