import api from 'api/api';
import { useEffect, useState } from 'react';
import CheckTable from './components/CheckTable';
const CategoryCrud = () => {
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const columnsDataServices = [
    {
      Header: 'Etiqueta',
      accessor: 'label',
    },
    {
      Header: 'Descrição',
      accessor: 'description',
    },
    {
      Header: 'Ação',
      accessor: 'action',
    },
  ];

  /*useEffect(() => {
    fetchCustomers(currentPage);
  }, [currentPage]);*/

  useEffect(() => {

    getData();

    /*api
      .get('category/')
      .then((response) => {
        const item = response.data;

        console.log('item: ', item);
        console.log('item.categorias: ', item.categories);

        const result = {
          //_id: item._id,
          categories: item.categories, //item.msgs,
        };

        //setConfigMessages(result);
        setTableData(result.categories);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });*/
  }, []);


  const getData = () => {

    api
      .get('category/')
      .then((response) => {
        const item = response.data;

        console.log('item: ', item);
        console.log('item.categorias: ', item.categories);

        const result = {
          //_id: item._id,
          categories: item.categories, //item.msgs,
        };

        //setConfigMessages(result);
        setTableData(result.categories);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

  }


  const fetchCustomers = (page) => {
    api
      .get(`service?page=${page}&size=${itemsPerPage}`)
      .then((response) => {
        const result = response.data.services.map((item) => {
          return {
            id: item._id,
            name: item.name,
            cost: parseFloat(item.cost.$numberDecimal).toFixed(2),
          };
        });
        setTableData(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });
  };
  return (
    <CheckTable
      columnsData={columnsDataServices}
      tableData={tableData}
      totalPages={totalPages}
      currentPage={currentPage}
      onPageChange={(newPage) => setCurrentPage(newPage)}
      getData={getData}
    />
  );
};

export default CategoryCrud;
