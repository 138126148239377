import React, { useEffect, useMemo, useState } from 'react';
import Card from 'components/card';
import { Bars } from 'react-loader-spinner';
//import CardMenu from 'components/card/CardMenu';
//import Checkbox from 'components/checkbox';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';


import {
  MdAdd,
  MdDelete,
  MdEdit,
  MdOutlineRepeat,
  MdSearch,
  MdPowerSettingsNew,
} from 'react-icons/md';

import { useDisclosure } from '@chakra-ui/hooks';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import api from 'api/api';
import Toast from 'components/toast';
import Swal from 'sweetalert2';
import Datepicker from 'react-tailwindcss-datepicker';
import dayjs from 'dayjs';
import RenovationModal from './RenovationModal';

let validateDateBefore;


const CheckTable = (props) => {
  const { columnsData, tableData, cabeçalo, fetchCustomers } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customerName, setCustomerName] = useState('');
  const [customerWhatsApp, setCustomerWhatsApp] = useState('');
  /*const [customerLogin, setCustomerLogin] = useState('');
  const [customerPassword, setCustomerPassword] = useState('');
  const [customerDevice, setCustomerDevice] = useState('');
  const [customerMAC, setCustomerMAC] = useState('');
  const [customerKey, setCustomerKey] = useState('');
  const [customerApps, setCustomerApps] = useState('');*/
  const [customerService, setCustomerService] = useState('');
  const [customerServiceOptions, setCustomerServiceOptions] = useState([]);
  const [customerPlanOptions, setCustomerPlanOptions] = useState([]);
  const [customerPlan, setCustomerPlan] = useState('');
  const [customerComment, setCustomerComment] = useState('');
  const [customerInvoice, setCustomerInvoice] = useState('payed');
  const [customerValidateDate, setCustomerValidateDate] = useState('');
  const [customerSendNotificationOn, setCustomerSendNotificationOn] = useState({
    sendMsg: true,
  });
  const [customerStatus, setCustomerStatus] = useState();
  const [customerId, setCustomerId] = useState('');
  const [modalTitle, setModalTittle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openRenovationModal, setOpenRenovationModal] = useState(false);
  const [openSendMessageModal, setOpenSendMessageModal] = useState(false);
  const [dadosCliente, setDadosCliente] = useState();

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: Number.MAX_SAFE_INTEGER,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  };


  // --------
  const [fields, setFields] = useState([]);


  // ---------------------------------------------------------------------------------------------------------------------------
  // IMPLEMENTAÇÃO P/ TRABALHAR C/ AS CATEGORIAS

  // Estado para armazenar as categorias escolhidas
  const [categoriasEscolhidas, setCategoriasEscolhidas] = useState([]);


  // ---------------------------------------------------------------------------------------------------------------------------
  const handleRenovationModal = (value) => {
    setCustomerName(value.name);
    setCustomerWhatsApp(value.whatsapp);
    setCustomerService(value.serviceId._id);
    setCustomerPlan(value.planId._id);
    setCustomerInvoice(value.invoice);
    setCustomerValidateDate({
      startDate: value.endDate,
      endDate: value.endDate,
    });
    setCustomerId(value.id);

    setCustomerComment(value.comment);
    setCustomerSendNotificationOn(value.sendNotificationOn);
    setCustomerStatus(value.status);
    setCustomerId(value.id);
    setCategoriasEscolhidas(value.categoryDynamic || []);

    // ----------------------------
    // CARREGA OS DADOS DE CADA CAMPO DINÂMICO
    let fields_the_customer = value?.dynamicFields?.dynamicFields || [];
    const fieldsUpdate = fields.map(field => {
      // Encontra o campo equivalente em fields_the_customer com base na chave "label"
      const customerField = fields_the_customer.find(cf => cf.label === field.label) || {};

      // Atualiza o campo atual (field) com as informações do campo equivalente em fields_the_customer
      if (customerField._id) field._id = customerField._id;
      // field.label = customerField.label; // Descomente se quiser sempre atualizar label
      // field.options = customerField.options; // Descomente se quiser sempre atualizar options

      // Atualiza o valor do campo com base nas condições especificadas
      if (customerField.value && field.type === 'text') {
        console.log('field.label: ', field.label);
        console.log('customerField.label: ', customerField.label);
        field.value = customerField.value;
      } else if (customerField.value && field.type === 'select' && field.options.indexOf(customerField.value) !== -1) {
        field.value = customerField.value;
      } else {
        // Se não atender a nenhuma condição, define o valor como uma string vazia
        field.value = '';
      }

      return field; // Retorna o campo atualizado para o novo array fieldsUpdate
    });

    // ----------------------------

    console.log('fieldsUpdate: ', fieldsUpdate);
    setFields(fieldsUpdate);

    setOpenRenovationModal(true);
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // SWITCH "STATUS" CLIENTE
  const toggleStatus = (dadosCliente) => {

    dadosCliente.status = !dadosCliente.status;

    setCustomerStatus(dadosCliente.status);

    editCustomer(dadosCliente);

  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // ENVIA DADOS P/ "ATUALIZAÇÃO" DE UM CLIENTE
  const editCustomer = (dadosCliente) => {
    setIsLoading(true);

    // SE A DATA DE VENCIMENTO TIVER SIDO ALTERADA, RESETA OS ENVIOS
    //console.log('validateDateBefore: ', validateDateBefore);
    //console.log('customerSendNotificationOn(antes): ', customerSendNotificationOn);

    if (validateDateBefore !== customerValidateDate.endDate) {

      console.log('Data de venc. alterada');

      setCustomerSendNotificationOn((prevState) => ({
        ...prevState,
        ultimoEnvio: false,
      }));
    }

    //console.log('customerService:', customerService);
    //console.log('customerPlan:', customerPlan);
    //console.log('dadosCliente: ', dadosCliente);

    let dados = dadosCliente ? {
      name: dadosCliente.name,
      whatsapp: dadosCliente.whatsapp,
      /*login: dadosCliente.login,
      password: dadosCliente.password,
      device: dadosCliente.device,
      mac: dadosCliente.mac,
      key: dadosCliente.key,
      apps: dadosCliente.apps,*/
      serviceId: dadosCliente.serviceId._id,
      planId: dadosCliente.planId._id,
      invoice: dadosCliente.invoice,
      validateDate: dadosCliente.validate,
      sendNotificationOn: dadosCliente.sendNotificationOn,
      comment: dadosCliente.comment,
      categoryDynamic: dadosCliente.categoryDynamic,
      status: dadosCliente.status,
      dynamicFields: dadosCliente.dynamicFields || { dynamicFields: [] },
    } :

      {
        name: customerName,
        whatsapp: customerWhatsApp,
        /*login: customerLogin,
        password: customerPassword,
        device: customerDevice,
        mac: customerMAC,
        key: customerKey,
        apps: customerApps,*/
        serviceId: document.getElementById('customer.service')?.value,
        planId: document.getElementById('customer.plan')?.value,
        invoice: customerInvoice,
        validateDate: customerValidateDate.endDate,
        sendNotificationOn: customerSendNotificationOn,
        comment: customerComment,
        categoryDynamic: categoriasEscolhidas[0] ? categoriasEscolhidas : null,
        status: customerStatus,
        dynamicFields: {
          dynamicFields: fields
        },
      }

    const customerId_ = dadosCliente ? dadosCliente.id : customerId
    console.log('dados.dynamicFields: ', dados.dynamicFields);
    //console.log('customerId(definida): ', customerId_);

    /*try {
      dados.serviceId = document.getElementById('customer.service').value;
      dados.planId = document.getElementById('customer.plan').value;
    } catch {}*/

    api
      .patch('customer/' + customerId_, dados)
      .then((response) => {
        //console.log(response.data);
        fetchCustomers();

        Toast.fire({
          icon: 'success',
          title: 'Seu cliente foi editado com sucesso!',
        });
        onClose();
        setTimeout(() => {
          //window.location.reload();
          // -------------------------------------------------------------------
          // -------------------------------------------------------------------
        }, 1500);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log('Erro ao editar cliente: ', error);
        Toast.fire({
          icon: 'error',
          title: 'Erro ao editar cliente, tente novamente mais tarde.',
        });
      });
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {

    // OBTEM OS DADOS DE "SERVIÇOS"
    api
      .get('service')
      .then((response) => {
        const result = response.data.services.map((item) => {
          return { name: item.name, id: item._id };
        });
        setCustomerServiceOptions(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

    // OBTEM OS DADOS DOS "PLANOS"
    api
      .get('plan')
      .then((response) => {
        const result = response.data.plans.map((item) => {
          return { name: item.name, id: item._id };
        });
        setCustomerPlanOptions(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

    // OBTEM OS DADOS DAS "CATEGORIAS"
    /*api
      .get('category')
      .then((response) => {
        const result = response.data.categories.map((item) => {
          return { label: item.label, description: item.description };
        });
        setCategorias(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });*/

    // OBTEM OS DADOS DOS "CAMPOS DINÂMICOS"
    api
      .get('dynamic-fields')
      .then((response) => {
        //console.log('response.data(dynamic-fields): ', response.data);
        const result = response.data.map((item) => {
          return { _id: item._id, label: item.label, type: item.type, options: item.options, value: '' };
        });
        setFields(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });


  }, []);


  return (
    <Card extra={'w-full h-full mt-5 sm:overflow-auto px-6'}>
      <header className="relative flex items-center justify-between pt-4 ">
        <div className="text-xl text-center font-bold text-navy-700 mx-auto dark:text-white">
          {cabeçalo}
        </div>
      </header>

      <RenovationModal
        id={customerId}
        name={customerName}
        whatsapp={customerWhatsApp}
        service={customerServiceOptions}
        serviceId={customerService}

        plan={customerPlanOptions}
        planId={customerPlan}
        validateDate={customerValidateDate.endDate}
        fields={fields}

        fetchCustomers={fetchCustomers}
        //currentPage={currentPage}
        open={openRenovationModal}
        close={() => setOpenRenovationModal(false)}
      />


      {/* ------------------------------------------------------------------------------------------------------------------------ */}
      {/* TELA DA TABELA */}
      <div
        className={`smallScrollbar mt-8 overflow-x-scroll xl:overflow-x-hidden` /* ${carregando ? 'hidden' : ''} */}
        style={{ maxHeight: '630px', overflow: 'auto' }}
      >
        <table
          {...getTableProps()}
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead
            className='bg-white dark:!bg-navy-800 z-10 sticky top-0'
          >
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] _pr-16 _text-start text-center dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    //console.log('cell: ', cell);
                    if (cell.column.Header === 'Nome') {
                      //console.log('original(Vc): ', cell.row.original);
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {' '}
                          {cell.value}{' '}
                        </p>
                      );
                    } else if (cell.column.Header === 'Fatura') {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value === 'payed' ? (
                              <div className="rounded-lg text-green-500 px-2 py-1 text-xs font-bold uppercase _text-white transition duration-200 dark:text-green-700">
                                Pago
                              </div>
                            ) : (
                              <div className="rounded-lg text-orange-500 px-2 py-1 text-xs font-bold uppercase _text-white transition duration-200 dark:text-orange-400">
                                Pendente
                              </div>
                            )}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'Data de expiração') {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {addDays(
                              new Date(
                                new Date(cell.value).toLocaleString('en', {
                                  timeZone: 'America/Sao_Paulo',
                                }),
                                1,
                              ),
                            ) >= new Date() ? (
                              <div className="rounded-lg text-green-500 px-2 py-1 text-xs font-bold uppercase _text-white transition duration-200 dark:text-green-400">
                                {cell.value}
                              </div>
                            ) : (
                              <div className="rounded-lg text-red-500 px-2 py-1 text-xs font-bold uppercase _text-white transition duration-200 dark:text-orange-800">
                                {cell.value}
                              </div>
                            )}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'WhatsApp') {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    // COLUNA - BOTÕES AÇÃO
                    else if (cell.column.Header === 'Ação') {
                      let statusCliente = row.original.status;
                      statusCliente = statusCliente === false ? false : (statusCliente === true ? true : true);
                      data = (
                        <div className="flex items-center gap-2">

                          {// ------------------------ BOTÃO ATIVAR/DESATIVAR ------------------------

                            statusCliente == true ?
                              < button
                                onClick={() => toggleStatus(row.original)}
                                className="flex items-center justify-center rounded-xl p-1 text-2xl text-white transition duration-200 hover:cursor-pointer bg-green-500 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700 dark:text-white  _ampliar"
                                title='Desativar'
                              >
                                <MdPowerSettingsNew />
                              </button> :

                              < button
                                onClick={() => toggleStatus(row.original)}
                                className="flex items-center justify-center rounded-xl p-1 text-2xl text-white transition duration-200 hover:cursor-pointer bg-gray-500 hover:bg-gray-600 dark:bg-[#6a6a6a] dark:hover:bg-gray-800 dark:text-white  _ampliar"
                                title='Ativar'
                              >
                                <MdPowerSettingsNew />
                              </button>
                          }

                          {/* ------------------------ BOTÃO RENOVAR ------------------------ */}
                          <button
                            onClick={() => handleRenovationModal(row.original)}
                            className="flex items-center justify-center rounded-xl bg-teal-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200 ampliar"
                            title='Renovar cliente'
                          >
                            <MdOutlineRepeat />
                          </button>

                          {/* ------------------------ BOTÃO DELETAR ------------------------ */}
                          {/** /}<button
                            onClick={() => {
                              confirmDeleteCustomer(row.original.id);
                            }}
                            className="flex items-center justify-center rounded-xl bg-red-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-red-700 active:bg-brand-700 dark:bg-red-500 dark:text-white dark:hover:bg-red-700 dark:active:bg-red-200 ampliar"
                            title='Excluir cliente'
                          >
                            <MdDelete />
                          </button>{/**/}


                        </div>
                      );
                    }

                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pt-[14px] _pb-[16px] text-center sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default CheckTable;
