import React, { useEffect, useMemo, useState } from 'react';
import Card from 'components/card';
import { Bars } from 'react-loader-spinner';
import InputField from 'components/fields/InputField';
//import CardMenu from 'components/card/CardMenu';
//import Checkbox from 'components/checkbox';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';


import {
  MdAdd,
  MdDelete,
  MdEdit,
  MdOutlineRepeat,
  MdSearch,
  MdCached,
  MdPowerSettingsNew,
} from 'react-icons/md';

import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
} from "react-icons/fa";

import { useDisclosure } from '@chakra-ui/hooks';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import api from 'api/api';
import Toast from 'components/toast';
import Swal from 'sweetalert2';
import Datepicker from 'react-tailwindcss-datepicker';
import dayjs from 'dayjs';
import RenovationModal from './RenovationModal';

let validateDateBefore;


const CheckTable = (props) => {
  const { columnsData, tableData, cabeçalo, fetchCustomers, fetchTransactions } = props;

  //console.log('Dados tab relatório: ', tableData);
  //console.log('columnsData relatório: ', columnsData);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customerName, setCustomerName] = useState('');
  const [customerWhatsApp, setCustomerWhatsApp] = useState('');
  /*const [customerLogin, setCustomerLogin] = useState('');
  const [customerPassword, setCustomerPassword] = useState('');
  const [customerDevice, setCustomerDevice] = useState('');
  const [customerMAC, setCustomerMAC] = useState('');
  const [customerKey, setCustomerKey] = useState('');
  const [customerApps, setCustomerApps] = useState('');*/
  const [customerService, setCustomerService] = useState('');
  const [customerServiceOptions, setCustomerServiceOptions] = useState([]);
  const [customerPlanOptions, setCustomerPlanOptions] = useState([]);
  const [customerPlan, setCustomerPlan] = useState('');
  const [customerComment, setCustomerComment] = useState('');
  const [customerInvoice, setCustomerInvoice] = useState('payed');
  const [customerValidateDate, setCustomerValidateDate] = useState('');
  const [customerSendNotificationOn, setCustomerSendNotificationOn] = useState({
    sendMsg: true,
  });
  const [customerStatus, setCustomerStatus] = useState();
  const [customerId, setCustomerId] = useState('');
  const [modalTitle, setModalTittle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openRenovationModal, setOpenRenovationModal] = useState(false);
  const [openSendMessageModal, setOpenSendMessageModal] = useState(false);
  const [dadosCliente, setDadosCliente] = useState();
  const [descriptionField, setDescriptionField] = useState('');
  const [valueField, setValueField] = useState(0);
  const [typeSelect, setTypeSelect] = useState('entrada');
  const [filterDate, setFilterDate] = useState(new Date().toISOString().slice(0, 7));

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: Number.MAX_SAFE_INTEGER,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  };


  // --------
  const [fields, setFields] = useState([]);


  // ---------------------------------------------------------------------------------------------------------------------------
  // IMPLEMENTAÇÃO P/ TRABALHAR C/ AS CATEGORIAS

  // Estado para armazenar as categorias escolhidas
  const [categoriasEscolhidas, setCategoriasEscolhidas] = useState([]);


  // ---------------------------------------------------------------------------------------------------------------------------
  const handleFilterDateChange = (e) => {

    const periodoTransações = e.target.value;
    //console.log('periodoTransações: ', periodoTransações);
    setFilterDate(periodoTransações);

    fetchTransactions(periodoTransações, periodoTransações);

  };



  // ---------------------------------------------------------------------------------------------------------------------------
  const handleRenovationModal = (value) => {
    setCustomerName(value.name);
    setCustomerWhatsApp(value.whatsapp);
    setCustomerService(value.serviceId._id);
    setCustomerPlan(value.planId._id);
    setCustomerInvoice(value.invoice);
    setCustomerValidateDate({
      startDate: value.endDate,
      endDate: value.endDate,
    });
    setCustomerId(value.id);

    setCustomerComment(value.comment);
    setCustomerSendNotificationOn(value.sendNotificationOn);
    setCustomerStatus(value.status);
    setCustomerId(value.id);
    setCategoriasEscolhidas(value.categoryDynamic || []);

    // ----------------------------
    // CARREGA OS DADOS DE CADA CAMPO DINÂMICO
    let fields_the_customer = value?.dynamicFields?.dynamicFields || [];
    const fieldsUpdate = fields.map(field => {
      // Encontra o campo equivalente em fields_the_customer com base na chave "label"
      const customerField = fields_the_customer.find(cf => cf.label === field.label) || {};

      // Atualiza o campo atual (field) com as informações do campo equivalente em fields_the_customer
      if (customerField._id) field._id = customerField._id;
      // field.label = customerField.label; // Descomente se quiser sempre atualizar label
      // field.options = customerField.options; // Descomente se quiser sempre atualizar options

      // Atualiza o valor do campo com base nas condições especificadas
      if (customerField.value && field.type === 'text') {
        console.log('field.label: ', field.label);
        console.log('customerField.label: ', customerField.label);
        field.value = customerField.value;
      } else if (customerField.value && field.type === 'select' && field.options.indexOf(customerField.value) !== -1) {
        field.value = customerField.value;
      } else {
        // Se não atender a nenhuma condição, define o valor como uma string vazia
        field.value = '';
      }

      return field; // Retorna o campo atualizado para o novo array fieldsUpdate
    });

    // ----------------------------

    console.log('fieldsUpdate: ', fieldsUpdate);
    setFields(fieldsUpdate);

    setOpenRenovationModal(true);
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // SWITCH "STATUS" CLIENTE
  const toggleStatus = (dadosCliente) => {

    dadosCliente.status = !dadosCliente.status;

    setCustomerStatus(dadosCliente.status);

    editCustomer(dadosCliente);

  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // ADICIONA UMA NOVA TRANSAÇÃO
  const addTransaction = () => {

    console.log('Entrou na func: addTransaction');
    // VERIFICA SE OS CAMPOS ESTÃO PREENCHIDOS COM DADOS VALIDOS
    if (!descriptionField) {
      Toast.fire({
        icon: 'warning',
        title: 'Atenção!\nO campo "DESCRIÇÃO" é obrigatório!',
        timer: 5_000,
      });
      return;
    }
    else if (!valueField || valueField < 1) {
      Toast.fire({
        icon: 'warning',
        title: 'Atenção!\nO campo "VALOR" não pode ser vazio ou igual a 0!',
        timer: 5_000,
      });
      return;
    }

    console.log('Descrição: ', descriptionField);
    console.log('Valor: ', valueField);
    console.log('Tipo: ', typeSelect);

    // ------------------
    api
      .post('transactions', {
        description: descriptionField,
        amount: valueField,
        type: typeSelect,
        category: 'Usuário',
      })
      .then((response) => {
        fetchCustomers();
        Toast.fire({
          icon: 'success',
          title: 'Transação adicionada com sucesso!',
        });
        //onClose();

        // RESETA OS CAMPOS
        //setDescriptionField('');
        //setValueField(0);
        //setTypeSelect('entrada');

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro ao adicionar transação: ', error);
        Toast.fire({
          icon: 'error',
          title: 'Erro ao adicionar transação, tente novamente mais tarde.',
        });
      });


  }



  // ---------------------------------------------------------------------------------------------------------------------------
  // SOLICITA CONFIRMAÇÃO P/ DELETAR UMA TRANSAÇÃO
  const confirmDeleteTransaction = (id, date) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Não será possivel reverter essa exclusão!',
      icon: 'warning',
      position: 'top',
      showCancelButton: true,
      confirmButtonColor: '#422afb',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      customClass: 'dark:bg-[#00205a] dark:text-[#eaf2f9]',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTransaction(id, date);
      }
    });
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // DELETA UMA TRANSAÇÃO
  const deleteTransaction = (id, date) => {
    setIsLoading(true);
    api
      .delete('transactions/' + id)
      .then((response) => {
        console.log(response.data);

        Toast.fire({
          icon: 'success',
          title: 'Transação excluída com sucesso!',
        });

        //fetchCustomers();
        const mesAnoAtual = new Date(date).toISOString().slice(0, 7);
        fetchTransactions(mesAnoAtual, mesAnoAtual);

        setIsLoading(false);
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: 'Erro ao excluir transação, tente novamente mais tarde.',
        });
      });
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // EDITA UMA TRANSAÇÃO
  const openEditTransaction = (dataTransaction) => {

    console.log('dataTransaction: ', dataTransaction);
    console.log('Hora da transação: ', new Date(dataTransaction.date).toISOString().slice(11, 16));

    Swal.fire({
      title: 'Editar transação',
      //text: 'Não será possivel reverter essa exclusão!',
      icon: 'info',
      position: 'top',
      showCancelButton: true,
      confirmButtonColor: '#422afb',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Salvar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      customClass: 'dark:bg-[#00205a] dark:text-[#eaf2f9]',
      html: `
      <div class="grid grid-cols-2 gap-2">
        <div class="items-center justify-center">
          <span class="text-sm text-navy-700 dark:text-white font-bold">
            Descrição
          </span>
          <input class="flex h-12 mx-auto text-center items-center justify-center rounded-xl border bg-white/0 px-3 text-sm outline-none  border-gray-200 dark:!border-white/10 dark:text-white"
          id="description-input" value="${dataTransaction.description}" />
        </div>
        <div>
          <span class="text-sm text-navy-700 dark:text-white font-bold">
            Valor
          </span>
          <input class="flex h-12 mx-auto text-center items-center justify-center rounded-xl border bg-white/0 px-3 text-sm outline-none  border-gray-200 dark:!border-white/10 dark:text-white"
          type="number" id="amount-input" value="${dataTransaction.amount}" />
        </div>

        <div>
          <span class="text-sm text-navy-700 dark:text-white font-bold">
            Data e hora
          </span>
          <input class="flex h-12 mx-auto items-center justify-center rounded-xl border bg-white/0 px-3 text-sm outline-none  border-gray-200 dark:!border-white/10 dark:text-white"
          type="datetime-local" id="date-input" value="${new Date(dataTransaction.date).toISOString().slice(0, 16)}" />
        </div>

        <div class="items-center justify-center">
          <span class="text-sm text-navy-700 dark:text-white font-bold">
            Tipo
          </span>
          <select class="flex h-12 mx-auto items-center justify-center rounded-xl border bg-white/0 px-3 text-sm outline-none  border-gray-200 dark:!border-white/10 dark:text-white"
          id="type-select" value="${dataTransaction.type}" />
            <option class="dark:bg-gray-700" value="entrada" ${dataTransaction.type == 'entrada' ? 'selected' : ''}>Entrada</option>
            <option class="dark:bg-gray-700" value="saída" ${dataTransaction.type == 'saída' ? 'selected' : ''}>Saída</option>
        </div>

      </div>
    `,
      focusConfirm: false,
      preConfirm: () => {
        return {
          description: document.querySelector("#description-input").value,
          amount: document.querySelector("#amount-input").value,
          type: document.querySelector("#type-select").value,
          date: document.querySelector("#date-input").value,
        };
      }
    }).then((result) => {
      console.log('result(Editar transação): ', result);
      if (result.isConfirmed) {

        //return
        const dados = {
          description: result.value.description,
          amount: result.value.amount,
          type: result.value.type,
          date: new Date(result.value.date),
        }

        api
          .patch('transactions/' + dataTransaction._id, dados)
          .then((response) => {
            //console.log(response.data);
            //fetchCustomers();
            const mesAnoSelecionados = new Date(result.value.date).toISOString().slice(0, 7);
            fetchTransactions(mesAnoSelecionados, mesAnoSelecionados);

            Toast.fire({
              icon: 'success',
              title: 'Transação editada com sucesso!',
            });
            //onClose();

            setIsLoading(false);
          })
          .catch((error) => {
            console.log('Erro ao editar transação: ', error);
            Toast.fire({
              icon: 'error',
              title: 'Erro ao editar transação, tente novamente mais tarde.',
            });
          });

      }
    });
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // ENVIA DADOS P/ "ATUALIZAÇÃO" DE UM CLIENTE
  const editCustomer = (dadosCliente) => {
    setIsLoading(true);

    // SE A DATA DE VENCIMENTO TIVER SIDO ALTERADA, RESETA OS ENVIOS
    //console.log('validateDateBefore: ', validateDateBefore);
    //console.log('customerSendNotificationOn(antes): ', customerSendNotificationOn);

    if (validateDateBefore !== customerValidateDate.endDate) {

      console.log('Data de venc. alterada');

      setCustomerSendNotificationOn((prevState) => ({
        ...prevState,
        ultimoEnvio: false,
      }));
    }

    //console.log('customerService:', customerService);
    //console.log('customerPlan:', customerPlan);
    //console.log('dadosCliente: ', dadosCliente);

    let dados = dadosCliente ? {
      name: dadosCliente.name,
      whatsapp: dadosCliente.whatsapp,
      /*login: dadosCliente.login,
      password: dadosCliente.password,
      device: dadosCliente.device,
      mac: dadosCliente.mac,
      key: dadosCliente.key,
      apps: dadosCliente.apps,*/
      serviceId: dadosCliente.serviceId._id,
      planId: dadosCliente.planId._id,
      invoice: dadosCliente.invoice,
      validateDate: dadosCliente.validate,
      sendNotificationOn: dadosCliente.sendNotificationOn,
      comment: dadosCliente.comment,
      categoryDynamic: dadosCliente.categoryDynamic,
      status: dadosCliente.status,
      dynamicFields: dadosCliente.dynamicFields || { dynamicFields: [] },
    } :

      {
        name: customerName,
        whatsapp: customerWhatsApp,
        /*login: customerLogin,
        password: customerPassword,
        device: customerDevice,
        mac: customerMAC,
        key: customerKey,
        apps: customerApps,*/
        serviceId: document.getElementById('customer.service')?.value,
        planId: document.getElementById('customer.plan')?.value,
        invoice: customerInvoice,
        validateDate: customerValidateDate.endDate,
        sendNotificationOn: customerSendNotificationOn,
        comment: customerComment,
        categoryDynamic: categoriasEscolhidas[0] ? categoriasEscolhidas : null,
        status: customerStatus,
        dynamicFields: {
          dynamicFields: fields
        },
      }

    const customerId_ = dadosCliente ? dadosCliente.id : customerId
    //console.log('dados.dynamicFields: ', dados.dynamicFields);
    //console.log('customerId(definida): ', customerId_);

    /*try {
      dados.serviceId = document.getElementById('customer.service').value;
      dados.planId = document.getElementById('customer.plan').value;
    } catch {}*/

    api
      .patch('customer/' + customerId_, dados)
      .then((response) => {
        //console.log(response.data);
        fetchCustomers();

        Toast.fire({
          icon: 'success',
          title: 'Seu cliente foi editado com sucesso!',
        });
        onClose();
        setTimeout(() => {
          //window.location.reload();
          // -------------------------------------------------------------------
          // -------------------------------------------------------------------
        }, 1500);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log('Erro ao editar cliente: ', error);
        Toast.fire({
          icon: 'error',
          title: 'Erro ao editar cliente, tente novamente mais tarde.',
        });
      });
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {

    // OBTEM OS DADOS DE "SERVIÇOS"
    api
      .get('service')
      .then((response) => {
        const result = response.data.services.map((item) => {
          return { name: item.name, id: item._id };
        });
        setCustomerServiceOptions(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

    // OBTEM OS DADOS DOS "PLANOS"
    api
      .get('plan')
      .then((response) => {
        const result = response.data.plans.map((item) => {
          return { name: item.name, id: item._id };
        });
        setCustomerPlanOptions(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

    // OBTEM OS DADOS DAS "CATEGORIAS"
    /*api
      .get('category')
      .then((response) => {
        const result = response.data.categories.map((item) => {
          return { label: item.label, description: item.description };
        });
        setCategorias(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });*/

    // OBTEM OS DADOS DOS "CAMPOS DINÂMICOS"
    api
      .get('dynamic-fields')
      .then((response) => {
        //console.log('response.data(dynamic-fields): ', response.data);
        const result = response.data.map((item) => {
          return { _id: item._id, label: item.label, type: item.type, options: item.options, value: '' };
        });
        setFields(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });


  }, []);


  return (
    <Card extra={'w-full h-full mt-5 sm:overflow-auto px-6 py-5'}>

      {/* ============================================      MODAIS      ============================================ */}

      <RenovationModal
        id={customerId}
        name={customerName}
        whatsapp={customerWhatsApp}
        service={customerServiceOptions}
        serviceId={customerService}

        plan={customerPlanOptions}
        planId={customerPlan}
        validateDate={customerValidateDate.endDate}
        fields={fields}

        fetchCustomers={fetchCustomers}
        //currentPage={currentPage}
        open={openRenovationModal}
        close={() => setOpenRenovationModal(false)}
      />


      {/* ============================================      TABELA - PARTE SUPERIOR      ============================================ */}
      <header className="relative _flex items-center justify-between pt-2 ">

        {/* ------------------------------- TÍTULO ------------------------------- */}
        <div className="text-xl text-center font-bold text-navy-700 mx-auto dark:text-white">
          {cabeçalo}
        </div>



        <div className="relative flex items-center justify-between mt-2 ">

          {/* ------------------------------- CAMPO DESCRIÇÃO ------------------------------- */}
          <InputField
            //id="customer.name"
            type="text"
            extra="items-center flex flex-row"
            value={descriptionField}
            onChange={(e) => setDescriptionField(e.target.value)}
            placeholder="Descrição..."
          />

          {/* ------------------------------- CAMPO VALOR ------------------------------- */}
          <InputField
            //id="customer.name"
            type="number"
            extra="items-center flex flex-row"
            value={valueField}
            onChange={(e) => setValueField(e.target.value)}
            placeholder="Valor..."
          />

          {/* ------------------------------- SELECT TIPO ------------------------------- */}
          <select
            id="customer.service"
            class="flex h-12 w-40 ml-3 text-center items-center justify-center rounded-xl border bg-white/0 px-3 text-sm outline-none dark:!border-white/10"
            value={typeSelect}
            onChange={(e) => setTypeSelect(e.target.value)}
          >
            {<option className="dark:bg-gray-700" value={'entrada'}>
              {'entrada'}
            </option>}
            {<option className="dark:bg-gray-700" value={'saída'}>
              {'saída'}
            </option>}
          </select>

          {/* ------------------------------- BOTÃO ADICIONAR TRANSAÇÃO ------------------------------- */}
          <button
            onClick={() => {
              addTransaction();
            }}
            className="flex items-center justify-center rounded-full bg-brand-500 p-2 ml-3 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-300 dark:text-white dark:hover:bg-brand-400 dark:active:bg-brand-200 ampliar"
            title='Adicionar transação'
          >
            <MdAdd />
          </button>

          {/* ------------------------------- BOTÃO ATUALIZAR LISTA DE TRANSAÇÕES ------------------------------- */}
          <button
            onClick={() => {
              fetchCustomers();
            }}
            className="flex items-center justify-center rounded-full bg-brand-500 p-2 ml-3 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-cyan-600 dark:text-white dark:hover:bg-cyan-700 dark:active:bg-cyan-600 rotacionar_ampliar"
            title='Atualizar lista de transações'
          >
            <MdCached />
          </button>

          {/*}
          <div className="ml-4 mr-auto">
            <select
              id="service"
              className=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
              onChange={(e) => {
                setCurrentPage(1);
                setItemsPerPage(e.target.value);
              }}
            >
              <option className="dark:bg-gray-700" value={10}>
                10 itens por página
              </option>
              <option className="dark:bg-gray-700" value={50}>
                50 itens por página
              </option>
              <option className="dark:bg-gray-700" value={100}>
                100 itens por página
              </option>
            </select>
          </div>
          {*/}

          {/* ------------------------------- CAMPO FILTRO DATA ------------------------------- */}
          <div className='ml-auto'>
            {/*<span className="text-sm text-navy-700 dark:text-white font-bold">
              Valor
            </span>*/}
            <input className="flex h-12 mx-auto text-center items-center justify-center rounded-xl border bg-white/0 px-3 text-sm outline-none  border-gray-200 dark:!border-white/10 dark:text-white"
              type="month"
              value={filterDate}
              onChange={handleFilterDateChange}
            />
          </div>

          {/* ------------------------------- CAMPO PESQUISA  ------------------------------- */}
          <div className="flex flex-row items-center space-x-2 ml-auto">
            <MdSearch />
            <InputField
              //id="customer.name"
              type="text"
              extra="_w-full"
              //value={searchTerm}
              //onChange={handleSearchChange}
              placeholder="Pesquisar..."
            />
          </div>

        </div>

      </header>




      {/* ============================================      TABELA - CABEÇALHO E CORPO      ============================================ */}
      <div
        className={`smallScrollbar mt-8 overflow-x-scroll xl:overflow-x-hidden` /* ${carregando ? 'hidden' : ''} */}
        style={{ maxHeight: '630px', overflow: 'auto' }}
      >
        <table
          {...getTableProps()}
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead
            className='bg-white dark:!bg-navy-800 z-10 sticky top-0'
          >
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] _pr-16 _text-start text-center dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    /*console.log('cell.column: ', cell.column);
                    console.log('cell.column.Header: ', cell.column.Header);
                    console.log('cell.value: ', cell.value);*/
                    //console.log('cell: ', cell);
                    if (cell.column.Header === 'Descrição') {
                      //console.log('original: ', cell.row.original);
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.row.original.description}
                        </p>
                      );
                    } else if (cell.column.Header === 'Valor') {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {parseFloat(cell.value).toFixed(2)}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'Tipo') {
                      data = (
                        <div className="flex items-center">
                          {cell.value == 'entrada' ?
                            <div
                              className="flex items-center justify-center rounded-xl p-1 text-2xl _text-white text-green-600 transition duration-200 _bg-green-500 _dark:bg-green-600 _dark:text-white"
                              title='entrada'
                            >
                              <FaArrowAltCircleUp />
                            </div>
                            :
                            <div
                              className="flex items-center justify-center p-1 text-2xl text-red-700 transition duration-200"
                              title='saída'
                            >
                              <FaArrowAltCircleDown />
                            </div>}

                        </div>
                      );
                    } else if (cell.column.Header === 'Data') {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {new Date(cell.value).toLocaleDateString('pt-BR', { timezone: 'America/Sao_Paulo', }) + '    '}
                            {new Date(cell.value).toLocaleTimeString('pt-BR', { timezone: 'America/Sao_Paulo', })}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'Categoria') {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    }
                    // COLUNA - BOTÕES AÇÃO
                    else if (cell.column.Header === 'Ação') {
                      let statusCliente = row.original.status;
                      statusCliente = statusCliente === false ? false : (statusCliente === true ? true : true);
                      data = (
                        <div className="flex items-center gap-2">

                          {// ------------------------ BOTÃO ATIVAR/DESATIVAR ------------------------

                            < button
                              onClick={() => openEditTransaction(row.original)}
                              className="flex items-center justify-center rounded-xl bg-brand-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ampliar"
                              title='Editar transação'
                            >
                              <MdEdit />
                            </button>
                          }

                          {/* ------------------------ BOTÃO DELETAR ------------------------ */}
                          {/**/}<button
                            onClick={() => {
                              confirmDeleteTransaction(row.original._id, row.original.date);
                            }}
                            className="flex items-center justify-center rounded-xl bg-red-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-red-700 active:bg-brand-700 dark:bg-red-500 dark:text-white dark:hover:bg-red-700 dark:active:bg-red-200 ampliar"
                            title='Excluir transação'
                          >
                            <MdDelete />
                          </button>{/**/}


                        </div>
                      );
                    }

                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pt-[14px] _pb-[16px] text-center sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default CheckTable;
