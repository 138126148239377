// Custom components
import { useState } from 'react';
import { Standard } from "@typebot.io/react";

const TestFlow = ({ 
  urlTypebot,
}) => {
  


  /* =========================================================================================================================== */
  return (
    <div className="text-base text-navy-700 mt-10 dark:text-white  md:mt-0">


      {/* ============================== TESTAR FLUXO ============================== */}
      {/*}<Standard
        typebot="fluxo-teste1"
        apiHost="https://apitypebot.ckdigital.xyz"
        style={{
          width: "", //max-content
          height: "70vh",
          borderRadius: "20px",
        }}
      />{*/}

      <iframe
        src={urlTypebot}
        //style="border: none; width: 100%; height: 70vh"
        style={{
          border: "none",
          width: "100%",
          height: "70vh",
          borderRadius: "20px",
        }}
      ></iframe>

    </div>
  );
}

export default TestFlow;
