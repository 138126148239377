import { useDisclosure } from '@chakra-ui/hooks';
//import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import Card from 'components/card';
import { useState, useRef, useEffect } from 'react';
import { MdEdit, MdInsertEmoticon, MdDelete, MdMessage, MdImage, } from 'react-icons/md';
import { RiFileVideoFill } from "react-icons/ri";
import TextField from './TextField';
import api from 'api/api';
import Toast from 'components/toast';
import MessageTriggers from './MessageTriggers';

import InputField from '../components/InputField';

const Widget = ({
  
  index,
  id_msg,
  taskName,
  //subtitle,
  intervalType,
  intervalValue,
  frequency,
  executionTime,
  categorySend,
  dataMsg,
  diaDisparo,

  status,
  //icon,
  value,
  handleUpdateConfigMessages,  
  
  abrirModal,
  setMessage,
  setMediaType,
  setLinkArq,
  setEditMsg,
  setTitulo,

  setCategorySendState,
  setIntervalValueState,
  setIntervalTypeState,
  setExecutionTimeState,
  setDiaDisparo,
}) => {

  /*useEffect(() => {
    if (modalData) {
      abrirModal(modalData);
    }
  }, [modalData]);*/


  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState({
    id_msg: id_msg,
    taskName: taskName,
    intervalType: intervalType,
    intervalValue: intervalValue,
    frequency: frequency,
    executionTime: executionTime,
    categorySend: categorySend,
    status: status,
    dataMsg: dataMsg,
    selectValue: dataMsg?.type || '',
    diaDisparo: diaDisparo,
  });

  //console.log('value.msgs: ', value.msgs);
  //console.log('index: ', index);


  // DEFINE O ICONE DE ACORDO COM O TIPO DA MSG
  const typeMsg = modalData.dataMsg.type;
  const icon = typeMsg == 'text' ? <MdMessage className="h-6 w-6 _text-[#a7d6e1]" /> : (typeMsg == 'image' ? <MdImage className="h-6 w-6 _text-[#cdca7c]" /> : (typeMsg == 'video' ? <RiFileVideoFill className="h-6 w-6 _text-[#8617ff]" /> : ''));

  const [isLoading, setIsLoading] = useState(false);


  const toggleStatus = () => {

    //value.msgs[index].status = !value.msgs[index].status;
    value.tasks[index].status = !value.tasks[index].status;

    setIsLoading(true);

    let body = { ...value };
    //console.log('body: ', body);

    api
      .post('schedule/create-tasks/' /*+ value._id*/, body)
      .then((response) => {
        Toast.fire({
          icon: 'success',
          title: 'O status do Agendamento foi alterado com sucesso!',
        });
        setTimeout(() => {
          console.log('response.data: ', response.data);
          handleUpdateConfigMessages(response.data);
        }, 1000);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

  };


  const deleteMsg = () => {

    value.tasks.splice(index, 1);

    setIsLoading(true);

    let body = { ...value };
    console.log('body: ', body);

    api
      .post('schedule/create-tasks/', body)
      .then((response) => {
        Toast.fire({
          icon: 'success',
          title: 'O agendamento foi excluido com sucesso!',
        });
        onClose();
        setTimeout(() => {
          console.log('response.data: ', response.data);
          handleUpdateConfigMessages(response.data);
        }, 1000);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

  };

  const openEditForm = () => {


    /*setModalData({
      id_msg: id_msg, //value.msgs[index].id_msg,
      title: title, //value.msgs[index].titulo, //types[type],
      desc: subtitle, //value.msgs[index].desc,
      msg: message, //msg, //value.msgs[index].msg, //value[type],
      diaDisparo: diaDisparo,
      horarioDisparo: horarioDisparo,
      dadosMedia: dadosMedia,
    });*/
    //setMessage(message); //value[type]

    /*setModalData((prevState) => ({
      ...prevState,
      taskName: taskName,                        
    }));*/
    setTitulo(taskName);
    setMessage(dataMsg?.msg);
    setLinkArq(dataMsg?.media || '');
    setMediaType(dataMsg?.type || '');

    setCategorySendState(categorySend);
    setIntervalValueState(intervalValue);
    setIntervalTypeState(intervalType);
    setExecutionTimeState(executionTime);
    setDiaDisparo(diaDisparo);

    setEditMsg(index);

    //onOpen();
    abrirModal(modalData);
  };


  // Retorna o JSX para renderizar o componente
  return (

    <div
      /*className="animate-fadeIn"
      style={{
        animationDelay: `${index * 8.5}s`,
      }}*/
      style={{
        animation: 'fadeIn 1s',
        animationDelay: `${index * 0.2}s`,
        animationFillMode: 'backwards'
      }}
    >
      <Card extra="!flex-row _flex-grow _items-center flex-col pt-[10px] pb-[15px] rounded-[20px]  sm:flex-row md:py-0"> {/* !flex-row flex-grow items-center rounded-[20px]  //  grid grid-cols-1 gap-3 rounded-[20px]  md:grid-cols-3 */}


        {/* ====================================== LISTA NA PAGINA AS MSGS EXISTENTES ====================================== */}

        {/* ----------------- ICONE ----------------- */}
        <div className="ml-[18px] mr-3 flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
            <span className="flex items-center text-brand-500 dark:text-white">
              {icon}
            </span>
          </div>
        </div>


        <div className='flex  flex-col flex-grow items-center rounded-[20px]  sm:flex-row'>

          {/* ----------------- TITULO E DESCRIÇÃO ----------------- */}
          <div className="h-50 ml-4 flex w-full flex-col justify-center sm:justify-start sm:ml-0 sm:mt-4"> {/* h-50 ml-4 flex w-full flex-col justify-center */}
            {/* ----------------- titulo ----------------- */}
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              {taskName}
            </h4>
            {/* ----------------- descrição ----------------- */}
            {/*<p className="font-dm text-sm font-medium text-gray-600">
              {subtitle}
            </p>*/}
          </div>


          {/* ----------------- BOTÕES AÇÕES ----------------- */}
          <div className="mt-4 flex w-full items-end space-x-5  sm:mt-0 sm:mr-5 md:justify-end"> {/* mr-5 flex w-full items-end justify-end space-x-5 */}
            {status !== true ? (
              <button
                onClick={() => toggleStatus()}
                className="rounded-lg bg-orange-500 px-3 py-2 text-xs font-bold uppercase text-white transition duration-200 dark:bg-orange-400">
                Desativado
              </button>
            ) : (
              <button
                onClick={() => toggleStatus()}
                className="rounded-lg bg-green-500 px-3 py-2 text-xs font-bold uppercase text-white transition duration-200 dark:bg-green-400">
                Ativado
              </button>
            )}

            <button
              onClick={() => openEditForm()}
              className="mr-auto flex justify-self-end rounded-xl bg-brand-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              title='Editar'
            >
              <MdEdit />
            </button>

            <button
              onClick={() => deleteMsg()}
              className="flex items-center justify-center rounded-xl bg-red-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-red-700 active:bg-brand-700 dark:bg-red-500 dark:text-white dark:hover:bg-red-700 dark:active:bg-red-200 ampliar"
              title='Excluir cliente'
            >
              <MdDelete />
            </button>

          </div>

        </div>

      </Card>
    </div>
  );
};

export default Widget;
