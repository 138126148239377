import React from 'react';

// Admin Imports
import MainDashboard from 'views/admin/default';
import WhatsappConnection from 'views/admin/whatsapp-connection';
import Services from 'views/admin/services';
import Plans from 'views/admin/plans';
import Category from 'views/admin/category';
import Messages from 'views/admin/messages';
import Customers from 'views/admin/customers';
import ConfigMessages from 'views/admin/config_messages';
//import Financial from 'views/admin/financial';
import AgendMessages from 'views/admin/agend_messages';
import DadosClientes from 'views/admin/dados_clientes';
import AutomatedResponses from 'views/admin/automated-responses';
import SettingsUser from 'views/admin/settings-user';
import Integrations from 'views/admin/integrations';
import Leads from 'views/admin/leads';

// Auth Imports
import SignIn from 'views/auth/SignIn';

// Icon Imports
import {
  MdAppSettingsAlt,
  MdBarChart,
  MdEditCalendar,
  MdGraphicEq,
  MdHome,
  MdLiveTv,
  MdLock,
  MdOutlineMessage,
  MdPersonAdd,
  MdQrCode2,
  MdOutlineTimer,
  MdOutlineTimerOff,
  MdCategory,
  MdFlashAuto,
  MdOutlineMultipleStop,
  MdGroupAdd,
  MdFormatListBulletedAdd
} from 'react-icons/md';

import { SiGoogleforms } from "react-icons/si";

import { BsFillPersonVcardFill } from "react-icons/bs";

import { GrSend } from "react-icons/gr";


const routes = [
  {
    name: 'Dashboard',
    layout: '/admin',
    path: 'default',
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  /*{
    name: 'Financeiro Extra',
    layout: '/admin',
    path: 'financial',
    icon: <MdBarChart className="h-6 w-6" />,
    component: <Financial />,
  },*/
  {
    name: 'Interação clientes',
    layout: '/admin',
    path: 'customers',
    icon: <BsFillPersonVcardFill className="h-6 w-6" />,
    component: <Customers />,

    children: [
      {
        name: 'Clientes',
        layout: '/admin',
        path: 'customers',
        icon: <MdPersonAdd className="h-6 w-6" />,
        component: <Customers />,
      },
      {
        name: 'Serviços',
        layout: '/admin',
        path: 'services',
        icon: <MdLiveTv className="h-6 w-6" />,
        component: <Services />,
      },
      {
        name: 'Planos',
        layout: '/admin',
        path: 'plans',
        icon: <MdEditCalendar className="h-6 w-6" />,
        component: <Plans />,
      },
      {
        name: 'Categorias',
        layout: '/admin',
        path: 'category',
        icon: <MdCategory className="h-6 w-6" />,
        component: <Category />,
      },
      {
        name: 'Campos clientes',
        layout: '/admin',
        path: 'dados_clientes',
        icon: <SiGoogleforms className="h-6 w-6" />,
        component: <DadosClientes />,
      },
    ],
  },
  
  
  {
    name: 'Serviços',
    layout: '/admin',
    path: 'services',
    icon: <MdLiveTv className="h-6 w-6" />,
    component: <Services />,
    hide: true,
  },
  {
    name: 'Planos',
    layout: '/admin',
    path: 'plans',
    icon: <MdEditCalendar className="h-6 w-6" />,
    component: <Plans />,
    hide: true,
  },
  {
    name: 'Categorias',
    layout: '/admin',
    path: 'category',
    icon: <MdCategory className="h-6 w-6" />,
    component: <Category />,
    hide: true,
  },
  {
    name: 'Campos clientes',
    layout: '/admin',
    path: 'dados_clientes',
    icon: <SiGoogleforms className="h-6 w-6" />,
    component: <DadosClientes />,
    hide: true,
  },
  

  {
    name: 'Mensagens',
    layout: '/admin',
    path: 'config-message-texts',
    icon: <GrSend className="h-6 w-6" />,
    component: <ConfigMessages />,
    children: [
      {
        name: 'Mens. De vencimento',
        layout: '/admin',
        path: 'config-message-texts',
        icon: <MdOutlineTimerOff className="h-6 w-6" />,
        component: <ConfigMessages />,
      },
      {
        name: 'Mens. Agendadas',
        layout: '/admin',
        path: 'agend-message',
        icon: <MdOutlineTimer className="h-6 w-6" />,
        component: <AgendMessages />,
      },
      {
        name: 'Mensagens enviadas',
        layout: '/admin',
        path: 'messages',
        icon: <MdOutlineMessage className="h-6 w-6" />,
        component: <Messages />,
      },
      {
        name: 'Respostas automatizadas',
        layout: '/admin',
        path: 'automated-responses',
        icon: <MdFlashAuto className="h-6 w-6" />,
        component: <AutomatedResponses />,
      },
    ]
  },




  {
    name: 'Leads',
    layout: '/admin',
    path: 'leads',
    icon: <MdFormatListBulletedAdd className="h-6 w-6" />,
    component: <Leads />,

    children: [
      {
        name: 'Clientes pre-cadastrados',
        layout: '/admin',
        path: 'leads',
        icon: <MdGroupAdd className="h-6 w-6" />,
        component: <Leads />,
      },
    ]
  },


  /*{
    name: 'Mens. De vencimento',
    layout: '/admin',
    path: 'config-message-texts',
    icon: <MdOutlineTimerOff className="h-6 w-6" />,
    component: <ConfigMessages />,
    hide: true,
  },*/
  {
    name: 'Mens. Agendadas',
    layout: '/admin',
    path: 'agend-message',
    icon: <MdOutlineTimer className="h-6 w-6" />,
    component: <AgendMessages />,
    hide: true,
  },
  {
    name: 'Mensagens enviadas',
    layout: '/admin',
    path: 'messages',
    icon: <MdOutlineMessage className="h-6 w-6" />,
    component: <Messages />,
    hide: true,
  },
  {
    name: 'Respostas automatizadas',
    layout: '/admin',
    path: 'automated-responses',
    icon: <MdFlashAuto className="h-6 w-6" />,
    component: <AutomatedResponses />,
    hide: true,
  },

  {
    name: 'Conectar WhatsApp',
    layout: '/admin',
    path: 'whatsapp',
    icon: <MdQrCode2 className="h-6 w-6" />,
    component: <WhatsappConnection />,
  },

  {
    name: 'Integrações',
    layout: '/admin',
    path: 'integrations',
    icon: <MdOutlineMultipleStop className="h-6 w-6" />,
    component: <Integrations />,
  },

  {
    name: 'Sign In',
    layout: '/auth',
    path: 'sign-in',
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    hide: true,
  },
  {
    name: 'Configurações',
    layout: '/admin',
    path: 'settings-user',
    icon: <MdQrCode2 className="h-6 w-6" />,
    component: <SettingsUser />,
    hide: true,
  },
];
export default routes;
