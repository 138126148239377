import api from 'api/api';
import { useEffect, useState } from 'react';
import CheckTable from './components/CheckTable';
const ServicesCrud = () => {
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const columnsDataServices = [
    {
      Header: 'Nome',
      accessor: 'name',
    },
    {
      Header: 'Contato',
      accessor: 'contact',
    },
    /*{
      Header: 'Interesses',
      accessor: 'concernment',
    },*/
    {
      Header: 'Ação',
      accessor: 'action',
    },
  ];

  useEffect(() => {
    fetchCustomers(currentPage);
  }, [currentPage]);

  const fetchCustomers = (page) => {
    api
      .get(`leads?page=${page}&size=${itemsPerPage}`)
      .then((response) => {
        console.log('Dados dos Serviços recebidos: ', response.data);
        const result = response.data.services.map((item) => {
          return {
            id: item._id,
            name: item.name,
            //cost: parseFloat(item.cost.$numberDecimal).toFixed(2),
            contact: item.contact,
            fields: item.fields,
          };
        });
        setTableData(result);

        // Atualize o estado totalPages com base no número total de registros retornados
        setTotalPages(Math.ceil(response.data.totalCount / itemsPerPage));

      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });
  };
  return (
    <CheckTable
      columnsData={columnsDataServices}
      tableData={tableData}
      totalPages={totalPages}
      currentPage={currentPage}
      onPageChange={(newPage) => setCurrentPage(newPage)}
      fetchCustomers={fetchCustomers}
    />
  );
};

export default ServicesCrud;
