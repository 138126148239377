import { useEffect, useRef, useState } from 'react';
import Card from 'components/card';
import api from '../../../../api/api';
//import { Button } from '@chakra-ui/react';
import {
  MdDelete,
} from 'react-icons/md';

export default function Filters({
  setPlanFilter,
  setServiceFilter,
  setStatusFilter,
  setBillingFilter,
  setCategoriesFilter,
  setStatusClienteFilter,
  setStartDate,
  setEndDate,
}) {
  const [customerPlanOptions, setCustomerPlanOptions] = useState([]);
  const [customerServiceOptions, setCustomerServiceOptions] = useState([]);

  const planSelect = useRef();
  const serviceSelect = useRef();
  const statusSelect = useRef();
  const billingSelect = useRef();
  const categoriesSelect = useRef();
  const statusClienteSelect = useRef();
  const startDateSelect = useRef();
  const endDateSelect = useRef();



  // ----------------------------------------------------------------------------------------------------------------------------
  // MANIPULADORES DOS CAMPOS DE FILTRO DE DATA
  const handleFilterDateStartChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleFilterDateEndChange = (event) => {
    setEndDate(event.target.value);
  };


  // ---------------------------------------------------------------------------------------------------------------------------
  // IMPLEMENTAÇÃO P/ TRABALHAR C/ AS CATEGORIAS

  // Estado para armazenar as categorias escolhidas
  const [categoriasEscolhidas, setCategoriasEscolhidas] = useState([]);
  // Estado para controlar o valor selecionado no select
  const [categoriaSelecionada, setCategoriaSelecionada] = useState('');

  // Lista de categorias (você pode buscar isso de uma API ou de qualquer outra fonte)
  const [categorias, setCategorias] = useState([]);

  // Manipulador de mudança no select
  const handleSelectCategoryChange = (event) => {
    var novaCategoria = event.target.value;

    [novaCategoria] = categorias.filter(c => c.description == novaCategoria);
    const [jáAdicionada] = categoriasEscolhidas.filter(c => c.description == novaCategoria.description);

    // Adiciona a nova categoria à lista de categorias escolhidas
    if (!jáAdicionada) setCategoriasEscolhidas([...categoriasEscolhidas, novaCategoria]);

    // Define a categoria selecionada de volta para a primeira opção
    setCategoriaSelecionada('');
  };

  // Manipulador de clique para remover uma categoria escolhida
  const handleRemoveCategoria = (categoria) => {
    const novasCategorias = categoriasEscolhidas.filter(c => c !== categoria);
    setCategoriasEscolhidas(novasCategorias);
  };


  useEffect(() => {

    // OBTEM OS DADOS DE "SERVIÇOS"
    api
      .get('service')
      .then((response) => {
        const result = response.data.services.map((item) => {
          return { name: item.name, id: item._id };
        });
        setCustomerServiceOptions(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

    // OBTEM OS DADOS DOS "PLANOS"
    api
      .get('plan')
      .then((response) => {
        const result = response.data.plans.map((item) => {
          return { name: item.name, id: item._id };
        });
        setCustomerPlanOptions(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

    // OBTEM OS DADOS DAS "CATEGORIAS"
    api
      .get('category')
      .then((response) => {
        const result = response.data.categories.map((item) => {
          return { label: item.label, description: item.description };
        });
        setCategorias(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

  }, []);

  const handleFilters = () => {
    const planFilter = planSelect.current.value;
    const serviceFilter = serviceSelect.current.value;
    const statusFilter = statusSelect.current.value;
    const billingFilter = billingSelect.current.value;
    const categoriesFilter = categoriasEscolhidas; //categoriesSelect.current.value;
    const statusClienteFilter = statusClienteSelect.current.value == 'all' ? 'all' : (statusClienteSelect.current.value == 'true' ? true : false);
    const startDateFilter = startDateSelect.current.value;
    const endDateFilter = endDateSelect.current.value;
    console.log('statusClienteFilter(Filter): ', statusClienteFilter);
    console.log('startDateFilter(Filter): ', startDateFilter);
    console.log('endDateFilter(Filter): ', endDateFilter);
    
    setPlanFilter(planFilter);
    setServiceFilter(serviceFilter);
    setStatusFilter(statusFilter);
    setBillingFilter(billingFilter);
    setCategoriesFilter(categoriesFilter);
    setStatusClienteFilter(statusClienteFilter);
    setStartDate(startDateFilter);
    setEndDate(endDateFilter);
  };

  const clearFilters = () => {
    planSelect.current.value = 'all';
    serviceSelect.current.value = 'all';
    statusSelect.current.value = 'all';
    billingSelect.current.value = 'all';
    //categoriesSelect.current.value = [];
    setCategoriasEscolhidas([]);
    statusClienteSelect.current.value = 'all';
    startDateSelect.current.value = '';
    endDateSelect.current.value = '';

    handleFilters();
  };

  return (
    <Card extra={'w-full h-full sm:overflow-auto px-6 mt-1'}>
      <div className="py-4 grid grid-cols-1 gap-5  md:grid-cols-4">


        {/* -------------------------------------------   FILTRO - PLANO   ------------------------------------------- */}
        <div>
          <label
            for="plan"
            class="text-sm font-bold text-navy-700 dark:text-white"
          >
            Plano
          </label>
          <select
            id="plan"
            class=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
            ref={planSelect}
          >
            <option value="all">Todos</option>
            {customerPlanOptions.map((option) => (
              <option className="dark:bg-gray-700" value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>


        {/* -------------------------------------------   FILTRO - SERVIÇO   ------------------------------------------- */}
        <div>
          <label
            for="service"
            class="text-sm font-bold text-navy-700 dark:text-white"
          >
            Serviço
          </label>
          <select
            id="service"
            class=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
            ref={serviceSelect}
          >
            <option value="all">Todos</option>
            {customerServiceOptions.map((option) => (
              <option className="dark:bg-gray-700" value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>


        {/* -------------------------------------------   FILTRO - STATUS VENCIMENTO   ------------------------------------------- */}
        <div>
          <label
            for="status"
            class="text-sm font-bold text-navy-700 dark:text-white"
          >
            Estado vencimento
          </label>
          <select
            id="status"
            class=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
            ref={statusSelect}
          >
            <option className="dark:bg-gray-700" value="all">Todos</option>
            <option className="dark:bg-gray-700" value="working">Dentro da validade</option>
            <option className="dark:bg-gray-700" value="ended">Vencido</option>
          </select>
        </div>


        {/* -------------------------------------------   FILTRO - FATURA   ------------------------------------------- */}
        <div>
          <label
            for="invoice"
            class="text-sm font-bold text-navy-700 dark:text-white"
          >
            Fatura
          </label>
          <select
            id="invoice"
            class=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
            ref={billingSelect}
          >
            <option className="dark:bg-gray-700" value="all">Todos</option>
            <option className="dark:bg-gray-700" value="payed">Pago</option>
            <option className="dark:bg-gray-700" value="pending">Pendente</option>
          </select>
        </div>
      </div>


      <div className="pb-4 grid grid-cols-1 gap-5  md:grid-cols-3">
        {/* -------------------------------------------   FILTRO - CATEGORIAS   ------------------------------------------- */}
        <div className="_mt-2 grid grid-cols-1 gap-2 max-w-[622px]  md:grid-cols-2">

          <div className={`w-full`}>
            <label
              for="invoice"
              class="text-sm font-bold text-navy-700 dark:text-white"
            >
              Categoria
            </label>
            <select
              id="categories"
              class="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
              value={categoriaSelecionada}
              onChange={handleSelectCategoryChange}
            //ref={categoriesSelect}
            >
              <option className="dark:bg-gray-700" value="addCategoria">Selecione uma categoria</option>
              {categorias.map((categoria, index) => (
                <option className="dark:bg-gray-700" key={index} value={categoria.description}>{categoria.description}</option>
              ))}
            </select>
          </div>


          {/* LISTA DE CATEGORIAS ESCOLHIDAS */}
          <div className={`smallScrollbar mt-7 border bg-[#0000000d] border-gray-200 dark:!border-white/10 rounded-xl p-2 max-h-[70px] overflow-auto flex flex-wrap`}>{/* grid grid-cols-1 gap-2 md:grid-cols-2 */}

            {categoriasEscolhidas.map((categoria, index) => (

              <div key={index} className='gap-1 mx-2 mt-1' style={{ display: '-webkit-box' }}>
                {/*console.log('categoria: ', categoria)*/}
                <div className='w-5 h-5 rounded-[100%] _bg-[#ffce8c]' style={{ background: categoria.label }}></div>
                <div>{categoria.description}</div>

                <button
                  onClick={() => {
                    handleRemoveCategoria(categoria)
                  }}
                  className="text-[20px] text-white text-gray-500 hover:cursor-pointer dark:text-gray-300 ampliar"
                  title='Excluir categoria'
                >
                  <MdDelete />
                </button>
              </div>
            ))}

          </div>

        </div>


        {/* -------------------------------------------   FILTRO - STATUS CLIENTE   ------------------------------------------- */}
        <div>
          <label
            for="statusCliente"
            class="text-sm font-bold text-navy-700 dark:text-white"
          >
            Estado cliente
          </label>
          <select
            id="statusCliente"
            class=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
            ref={statusClienteSelect}
          >
            <option className="dark:bg-gray-700" value="all">Todos</option>
            <option className="dark:bg-gray-700" value="true">Ativados</option>
            <option className="dark:bg-gray-700" value="false">Desativados</option>
          </select>
        </div>


        {/* -------------------------------------------   FILTRO - DATA E VENCIMENTO   ------------------------------------------- */}
        <div>
          <label
            for="statusCliente"
            class="text-sm font-bold text-navy-700 dark:text-white"
          >
            Data vencimento
          </label>

          <div className='rounded-xl border mt-2 py-1 px-3'>
            {/* CAMPO DATA DE INICIO */}
            <div className='flex'>
              {<span className="text-sm text-navy-700 dark:text-white font-bold">
                Inicio
              </span>}
              <input className="ml-auto h-5 text-center items-center justify-center rounded-xl border bg-white/0 px-3 text-sm outline-none  border-gray-200 dark:!border-white/10 dark:text-white"
                type="date"
              //value={startDate}
              //onChange={handleFilterDateStartChange}
                ref={startDateSelect}
              />
            </div>


            {/* CAMPO DATA DE FIM */}
            <div className='flex'>
              {<span className="text-sm text-navy-700 dark:text-white font-bold">
                Fim
              </span>}
              <input className="ml-auto h-5 text-center items-center justify-center rounded-xl border bg-white/0 px-3 text-sm outline-none  border-gray-200 dark:!border-white/10 dark:text-white"
                type="date"
              //value={endDate}
              //onChange={handleFilterDateEndChange}
                ref={endDateSelect}
              />
            </div>
          </div>
        </div>


      </div>


      <div className="py-2 space-x-2">
        <button
          onClick={() => handleFilters()}
          className="linear rounded-xl bg-brand-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
        >
          Filtrar
        </button>
        <button
          onClick={() => clearFilters()}
          className="linear rounded-xl border-2 border-red-500 px-5 py-2 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
        >
          Limpar
        </button>
      </div>
    </Card>
  );
}
