import React, { useState, useEffect } from 'react';
import Card from 'components/card';
import { Bars } from 'react-loader-spinner';

const Widget = ({ icon, title, subtitle, carregando }) => {

  const [animatedSubtitle, setAnimatedSubtitle] = useState(null);

  useEffect(() => {
    if (!carregando && typeof subtitle === 'number') {
      // Animação de contagem suave
      let startValue = 0;
      const endValue = subtitle;
      const duration = 1000; // Tempo da animação em milissegundos
      const increment = endValue / (duration / 10); // Incremento por intervalo de tempo

      const interval = setInterval(() => {
        startValue += increment;
        if (startValue >= endValue) {
          clearInterval(interval);
          startValue = endValue;
        }
        setAnimatedSubtitle(Math.ceil(startValue)); // Arredonda para cima para evitar valores fracionados
      }, 10);

      return () => clearInterval(interval);
    } else {
      setAnimatedSubtitle(subtitle);
    }
  }, [carregando, subtitle]);


  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px]">
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-white">
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600">{title}</p>

        {/*carregando ?*/}
        <div className={`mt-[5px] _mb-20 mx-auto text-center ${carregando ? '' : 'hidden'}`}>

          <Bars
            height="30"
            width="30"
            color="#49006e"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass={`w-[30px] mx-auto`}
            visible={true}
          />

          {/** /}<div className='mt-5 mx-auto text-center'>
            <span className='text-[25px]'>Carregando...</span>
          </div>{/**/}

        </div>
        <h4 className={`text-xl font-bold text-navy-700 dark:text-white  ${carregando ? 'hidden' : ''}`}>
          {/*subtitle*/}
          {animatedSubtitle}
        </h4>
      </div>
    </Card>
  );
};

export default Widget;
