import React, { Component } from 'react';
import Chart from 'react-apexcharts';

/*class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      chartOptions: {},
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: this.props.chartOptions,
    });
  }

  render() {
    console.log('state: ', this.state);
    console.log('chartOptions: ', this.state.chartOptions);
    console.log('chartData: ', this.state.chartData);

    return (
      <Chart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="bar"
        width="100%"
        height="100%"
      />
    );
  }
}*/


const BarChart = ({ options, series }) => {

  //console.log('options.xaxis: ', options.xaxis);
  //console.log('series[0]: ', series[0]);

  return (
    <div>
      {/* Renderize o gráfico usando os dados recebidos nas props */}
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};


export default BarChart;
