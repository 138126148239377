import React, { useState, useRef, useEffect } from 'react';
import Dropdown from '../../components/dropdown';
import { FiAlignJustify } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import navbarimage from '../../assets/img/layout/Navbar.png';
import { BsArrowBarUp } from 'react-icons/bs';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import { IoMdAlert } from "react-icons/io";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from 'react-icons/io';
import api from 'api/api';
import avatar from '../../assets/img/avatars/avatar12.jpg';
import Swal from 'sweetalert2';


const Navbar = (props) => {
  const { onOpenSidenav, brandText, name } = props;
  const [darkmode, setDarkmode] = useState(false);

  const [isVisible, setIsVisible] = useState(true);
  const lastScrollTop = useRef(0);
  const [notificationConnectWA, setNotificationConnectWA] = useState(false);

  useEffect(() => {

    /*setTimeout(() => {
      window.location.reload();
    }, 10_000);*/

    const darkmode_memory = localStorage.getItem('darkmode');
    if (darkmode_memory === 'true') {
      document.body.classList.add('dark');
      setDarkmode(true);
    } else {
      document.body.classList.remove('dark');
      setDarkmode(false);
    }

    // -----------------------------------------------------------------
    function handleScroll() {
      let st = window.pageYOffset || document.documentElement.scrollTop; // window.scrollY

      if (st > lastScrollTop.current + 50) {
        //setIsVisible(false);
        document.querySelector('nav').classList.add('top-[-100%]');
        document.querySelector('nav').classList.remove('top-4');
        lastScrollTop.current = st <= 0 ? 0 : st;
      } else if (st < lastScrollTop.current - 50) {
        //setIsVisible(true);
        try {

          document.querySelector('nav').classList.add('top-4');
          document.querySelector('nav').classList.remove('top-[-100%]');
          lastScrollTop.current = st <= 0 ? 0 : st;
        } catch { }
      }
      //lastScrollTop.current = st <= 0 ? 0 : st;
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);


  useEffect(() => {


    const intervalId = setInterval(() => {
      api
        .get('user/dadosUser')
        .then((response) => {
          const dadosUser = response.data;
          //console.log('dadosUser: ', dadosUser);
          console.log('isWork_WA: ', dadosUser.isWork_WA);

          setNotificationConnectWA((prevNotification) => {
            // Usando a forma de atualização do estado para evitar problemas de concorrência
            return dadosUser.isWork_WA ? false : true;
          });
        })
        .catch((error) => {
          console.error('Erro na requisição GET:', error);
        });
    }, 5 * 60_000);

    // Limpeza do intervalo quando o componente é desmontado ou quando o efeito é reexecutado
    return () => clearInterval(intervalId);


  }, [notificationConnectWA]);


  if (!isVisible) {
    return null;
  }

  return (
    <div className="sticky top-0 z-40 h-[89.5px] transition-all duration-1000">
      <nav className="relative transition-all duration-1000 top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
        <div className="ml-[6px]">
          <div className="h-6 w-[224px] pt-1">
            <a
              className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              href=" "
            >
              Pages
              <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                {' '}
                /{' '}
              </span>
            </a>
            <Link
              className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              to="#"
            >
              {brandText}
            </Link>
          </div>
          <p className="hidden shrink text-[33px] capitalize text-navy-700 dark:text-white sm:block">
            <Link
              to="#"
              className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
            >
              {brandText}
            </Link>
          </p>
        </div>

        <div className="relative mt-[3px] flex h-[61px] w-[100px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[200px] md:flex-grow-0 md:gap-1 xl:w-[200px] xl:gap-2">


          {/* ------------------------------------------------------------------------------------------------------------------ */}
          {/* ICONE ALERTA P/ RECONECTAR WA */}
          <button
            className={`text-[30px] text-yellow-600 dark:text-yellow-500 ${notificationConnectWA ? '' : 'hidden'}`}
            title='Atenção! O Whatsapp precisa ser reconectado!'
            onClick={() => {
              Swal.fire({
                title: 'Atenção!',
                text: 'O Whatsapp precisa ser reconectado!',
                icon: 'warning',
                position: 'top',
                //showCancelButton: true,
                confirmButtonColor: '#422afb',
                //cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                //cancelButtonText: 'Cancelar',
                reverseButtons: true,
                customClass: 'dark:bg-[#00205a] dark:text-[#eaf2f9]',
              })
            }}
          >
            <IoMdAlert />
          </button>


          {/* ------------------------------------------------------------------------------------------------------------------ */}
          {/* BOTÃO P/ ABRIR MENU */}
          <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white navBar" // xl:hidden //navBar
            onClick={onOpenSidenav}
          >
            <FiAlignJustify className="h-5 w-5" />
          </span>



          {/* ------------------------------------------------------------------------------------------------------------------ */}
          {/* start Horizon PRO * /}
          <Dropdown
            button={
              <p className="cursor-pointer">
                <IoMdInformationCircleOutline className="h-4 w-4 text-gray-600 dark:text-white" />
              </p>
            }
            children={
              <div className="flex w-[350px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div
                  style={{
                    backgroundImage: `url(${navbarimage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                  className="mb-2 aspect-video w-full rounded-lg"
                />
                <a
                  target="blank"
                  href="https://horizon-ui.com/pro?ref=live-free-tailwind-react"
                  className="px-full linear flex cursor-pointer items-center justify-center rounded-xl bg-brand-500 py-[11px] font-bold text-white transition duration-200 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Buy Horizon UI PRO
                </a>
                <a
                  target="blank"
                  href="https://horizon-ui.com/docs-tailwind/docs/react/installation?ref=live-free-tailwind-react"
                  className="px-full linear flex cursor-pointer items-center justify-center rounded-xl border py-[11px] font-bold text-navy-700 transition duration-200 hover:bg-gray-200 hover:text-navy-700 dark:!border-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-white/10"
                >
                  See Documentation
                </a>
                <a
                  target="blank"
                  href="https://horizon-ui.com/?ref=live-free-tailwind-react"
                  className="hover:bg-black px-full linear flex cursor-pointer items-center justify-center rounded-xl py-[11px] font-bold text-navy-700 transition duration-200 hover:text-navy-700 dark:text-white dark:hover:text-white"
                >
                  Try Horizon Free
                </a>
              </div>
            }
            classNames={'py-2 top-6 -left-[250px] md:-left-[330px] w-max'}
            animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          />{/**/}


          {/* ------------------------------------------------------------------------------------------------------------------ */}
          <div
            id='btn-Alt-Tema'
            className="cursor-pointer text-gray-600"
            onClick={() => {
              //console.log('Botão alterar tema clicado');
              if (darkmode) {
                document.body.classList.remove('dark');
                localStorage.setItem('darkmode', 'false');
                setDarkmode(false);
              } else {
                document.body.classList.add('dark');
                localStorage.setItem('darkmode', 'true');
                setDarkmode(true);
              }
            }}
          >
            {darkmode ? (
              <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
            ) : (
              <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
            )}
          </div>
          {/* Profile & Dropdown */}
          <Dropdown
            button={
              <img
                className="h-10 w-10 rounded-full cursor-pointer"
                src={avatar}
                title="Usuário"
              />
            }
            children={
              <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="p-4">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      👋 Hey, {name}
                    </p>{' '}
                  </div>
                </div>
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="flex flex-col p-4">
                  <Link
                    //key={subIndex}
                    to={'settings-user'}
                    //onClick={onClose}
                  >
                    Configurações da conta
                  </Link>
                  {/*}<a
                    href=" "
                    className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                  >
                    Configurações da conta
                  </a>{*/}
                  <a
                    href=" "
                    className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                    onClick={(e) => {
                      localStorage.setItem('AUTH_TOKEN', '');
                      localStorage.setItem('USER_DATA', '');
                      window.location.reload();
                    }}
                  >
                    Log Out
                  </a>
                </div>
              </div>
            }
            classNames={'py-2 top-8 -left-[180px] w-max'}
          />
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
