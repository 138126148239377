import { useEffect, useState, useRef } from 'react';
import Widget from './components/Widget';
import { IoMdHome } from 'react-icons/io';
import { MdBarChart, MdDashboard, MdMessage, MdSearch, MdAdd, MdInsertEmoticon } from 'react-icons/md';
import api from 'api/api';
import InputField from './components/InputField';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Fade/*, ScaleFade, Slide, SlideFade, Collapse*/ } from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';

import TextField from './components/TextField';
import Toast from 'components/toast';
import MessageTriggers from './components/MessageTriggers';

const MessagesConfiguration = () => {


  const [configMessages, setConfigMessages] = useState({ tasks: [] });

  const handleUpdateConfigMessages = (data) => {

    const result = {
      //_id: item._id,
      tasks: data.tasks, //item.msgs,
    };

    setConfigMessages(result);
  };

  // ----------------------------------------------------------------------------------------------------------------------

  // PARA CONTROLE DO MODAL
  const { isOpen, onOpen, onClose } = useDisclosure();

  const abrirModal = (data) => {
    console.log('Entrou na Func abrirModal');
    console.log('data: ', data);
    //console.log('editMsg: ', editMsg);

    setModalData(data);
    onOpen(true);

    // SE FOR UMA MSG DO TIPO MEDIA, ABRE O CAMPO DO LINK
    if (data?.dataMsg?.type === 'image' || data?.dataMsg?.type === 'video') {

      setTimeout(() => {

        document.querySelector('#pai-link-mulmidia').classList.remove('pb-0');
        document.querySelector('#pai-link-mulmidia').classList.add('pb-[96px]');
      }, 500);
    }
  };


  // Define o estado para armazenar conteúdo do titulo
  const [titulo, setTitulo] = useState('');

  const [mediaType, setMediaType] = useState('text');
  const [linkArq, setLinkArq] = useState('');

  const [editMsg, setEditMsg] = useState(false);

  const [modalData, setModalData] = useState({
    //id_msg: '',
    taskName: '',
    //desc: '',
    intervalType: 'days',
    intervalValue: 1,
    frequency: 1,
    executionTime: '',
    categorySend: 'addCategoria',
    status: true,
    dataMsg: {
      type: mediaType,
      media: linkArq,
      msg: '',
    },
    selectValue: 'text',
    recorrenciaType: 'recorrente',
    diaDisparo: 1,
    //selectTypeInterval: 'dias', //dias
  });

  const [isLoading, setIsLoading] = useState(false);

  // Define o conteúdo para o titulo da textarea
  const [tituloCampoTxtArea, setTituloCampoTxtArea] = useState('Mensagem');

  // Define a referência para o textarea
  const textFieldRef = useRef(); // Referência para o TextField

  // Define o estado para controlar se o seletor de emoticons está aberto ou não
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  // Define o estado para armazenar a mensagem atual no textarea
  const [message, setMessage] = useState('');

  // Define o estado para armazenar a opção de categoria p/ envio
  const [categorySendState, setCategorySendState] = useState('addCategoria');

  // Define o estado para armazenar o intervalo
  const [intervalValueState, setIntervalValueState] = useState(1);

  // Define o estado para armazenar o tipo de intervalo
  const [intervalTypeState, setIntervalTypeState] = useState('days');

  // Define o estado para armazenar o tipo de recorrencia
  const [recorrenciaTypeState, setRecorrenciaTypeState] = useState('recorrente');

  // Define o estado para armazenar a hora de disparo
  const [executionTimeState, setExecutionTimeState] = useState('');

  // Lista de categorias
  const [categorias, setCategorias] = useState([]);

  // Lista de Serviços
  const [customerServiceOptions, setCustomerServiceOptions] = useState([]);

  // Estado para dia de disparo
  const [diaDisparo, setDiaDisparo] = useState(1);


  // Define o estado para controlar a classe do seletor de emoticons
  const [emojiPickerClass, setEmojiPickerClass] = useState('hidden');

  // Define a função para alternar a visibilidade do seletor de emoticons
  const toggleEmojiPicker = () => {
    //console.log('Dados conj-emoticons: ', document.querySelector('#conj-emoticons').classList.contains('display') );
    //const conj_emoticons_display = document.querySelector('#conj-emoticons') ? document.querySelector('#conj-emoticons').classList.contains('display') : false;
    console.log('Display conj-emoticons: ', document.querySelector('#conj-emoticons')?.classList.contains('aparecer-pra-baixo'));
    if (document.querySelector('#conj-emoticons')?.classList.contains('aparecer-pra-baixo')) { //isEmojiPickerOpen
      setEmojiPickerClass('');
      /*setTimeout(() => {
        setIsEmojiPickerOpen(false);
      }, 1_000);*/ // Ajuste este valor para corresponder à duração da transição CSS
    } else {
      setIsEmojiPickerOpen(true);

      const element = document.querySelector('#conj-emoticons');
      element.style['margin-top'] = '-40%';
      element.style['display'] = '';
      //const marginCustom = Number('-' + $(seletor).css('height').replace('px', '')) - 20 + 'px';
      //element.style['margin-top'] = marginCustom;

      setEmojiPickerClass('aparecer-pra-baixo');
    }
  };

  // Define a função para lidar com o clique em um emoticon
  // Adiciona o emoticon clicado na posição do cursor no textarea e fecha o seletor de emoticons
  const handleEmojiClick = (emoji) => {
    if (textFieldRef.current) {
      const { selectionStart, value } = textFieldRef.current; //.querySelector('textarea'); //textareaRef.current;
      setMessage(value.slice(0, selectionStart) + emoji + value.slice(selectionStart));
      //setIsEmojiPickerOpen(false);
    }
  };

  // Define a função para lidar com a mudança no textarea
  // Atualiza a mensagem com o valor atual do textarea e mantém o foco no textarea
  const handleTextChange = (event) => {
    setMessage(event.target.value);
    //textareaRef.current.focus();
  };

  // DEFINE O TIPO DE MENSAGEM DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT TIPO MSG
  const handleSelectTipoMsgChange = (e) => {

    const tipoMsg = e.target.value;
    console.log('Select tipo msg: ', tipoMsg);

    if (tipoMsg === 'text') {

      // DEFINE O TITULO DO CAMPO 'TEXTAREA'
      setTituloCampoTxtArea('MENSAGEM');

      // RESETA OS DADOS P/ MSG DO MEDIA
      setMediaType('text');
      setLinkArq('');

      setModalData((prevState) => ({
        ...prevState,
        dataMsg: {
          type: tipoMsg,
          media: modalData?.dataMsg?.media || '',
          msg: modalData?.dataMsg?.msg || '',
        },
      }));

      document.querySelector('#pai-link-mulmidia').classList.add('pb-0');
      document.querySelector('#pai-link-mulmidia').classList.remove('pb-[96px]');
    }
    else if (tipoMsg === 'image' || tipoMsg === 'video') {

      // DEFINE O TITULO DO CAMPO 'TEXTAREA'
      setTituloCampoTxtArea('LEGENDA');

      // DEFINE OS DADOS P/ MSG DO MEDIA
      setModalData((prevState) => ({
        ...prevState,
        dataMsg: {
          type: tipoMsg,
          media: modalData?.dataMsg?.media || '',
          msg: modalData?.dataMsg?.msg || '',
        },
      }));

      setMediaType(tipoMsg);
      //setLinkArq(false);

      //document.querySelector('#linkMulmidia').classList.remove('hidden');

      const element = document.querySelector('#linkMulmidia');
      /*element.style['margin-top'] = '-40%';
      element.style['display'] = '';*/

      document.querySelector('#pai-link-mulmidia').classList.remove('pb-0');
      document.querySelector('#pai-link-mulmidia').classList.add('pb-[96px]');
      //element.classList.add('aparecer-pra-baixo');

      //element.classList.add('mt-0');
      //element.classList.remove('mt-[-40%]');
    }
  };

  // DEFINE O TIPO DE INTERVALO DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT TIPO DE INTERVALO
  const handleSelectTipoIntervaloChange = (e) => {

    const tipoIntervalo = e.target.value;
    console.log('Select tipo intervalo: ', tipoIntervalo);

    // DEFINE OS DADOS DO TIPO DE INTERVALO
    setModalData((prevState) => ({
      ...prevState,
      intervalType: tipoIntervalo,
    }));

    setIntervalTypeState(tipoIntervalo);
  };// Fim: handleSelectTipoIntervaloChange

  // DEFINE O TIPO DE RECORRENCIA DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT TIPO DE RECORRENCIA
  const handleSelectTipoRecorrencia = (e) => {

    const tipoRecorrencia = e.target.value;
    console.log('Select tipo recorrencia: ', tipoRecorrencia);

    // DEFINE OS DADOS DO TIPO DE RECORRENCIA
    setModalData((prevState) => ({
      ...prevState,
      recorrenciaType: tipoRecorrencia,
    }));

    setRecorrenciaTypeState(tipoRecorrencia);
  };// Fim: handleSelectTipoRecorrencia

  // DEFINE O TIPO DE CATEGORIA DE ENVIO DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT TIPO DE CATEGORIA
  const handleSelectTipoCategoriaChange = (e) => {

    const tipoCategoria = e.target.value;
    console.log('Select tipo categoria: ', tipoCategoria);

    // DEFINE OS DADOS DO TIPO DE CATEGORIA
    setModalData((prevState) => ({
      ...prevState,
      categorySend: tipoCategoria,
    }));

    setCategorySendState(tipoCategoria);
  };// Fim: handleSelectTipoCategoriaChange

  // DEFINE O DIA DE DISPARO DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT
  const handleSelectDiaDisparoChange = (e) => {

    const diaDisparo = e.target.value;
    console.log('Select dia disparo: ', diaDisparo);

    // DEFINE OS DADOS DO TIPO DE INTERVALO
    setModalData((prevState) => ({
      ...prevState,
      diaDisparo: diaDisparo,
    }));

    setDiaDisparo(diaDisparo);
  };// Fim: handleSelectTipoIntervaloChange


  const tipoMsgSelect = useRef();
  const tipoIntervaloSelect = useRef();
  const tipoCategoriaSelect = useRef();
  const tipoRecorrenciaSelect = useRef();
  const diaDisparoSelect = useRef();






  // =============================================================================================================================
  const editConfigMessages = (editMsg) => {
    setIsLoading(true);

    let body = configMessages;

    // VERIFICA SE É UMA EDIÇÃO OU ADIÇÃO DE UMA MSG
    console.log('body(criar agendamento): ', body);

    if (editMsg !== false) {
      body.tasks[editMsg] = modalData;
      setEditMsg(false);
    }
    else body.tasks.push(modalData);

    console.log('body: ', body);
    api
      .post('schedule/create-tasks/' /*+ body._id*/, body)
      .then((response) => {
        Toast.fire({
          icon: 'success',
          title: 'Sua mensagem foi Agendada com sucesso!',
        });
        onClose();
        //setTimeout(() => {
        console.log('Dados retornado: ', response.data);
        handleUpdateConfigMessages(response.data);
        //}, 1000);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });
  };// Fim Func editConfigMessages


  // PARA CONTROLE DO CAMPO DE PESQUISA
  // 1. Crie um novo estado para armazenar o valor do campo de pesquisa
  const [searchTerm, setSearchTerm] = useState('');

  // 2. Atualize esse estado sempre que o valor do campo de pesquisa mudar
  const handleSearchChange = (event) => {
    //console.log('Dados da pesquisa: ', event.target.value);
    setSearchTerm(event.target.value);
  };

  // ----------------------------------------------------------------------------------------------------------------------

  useEffect(() => {

    // OBTEM OS DADOS DE "AGENDAMENTO"
    api
      .get('schedule/get-all-tasks')
      .then((response) => {
        const item = response.data;

        //console.log('item.msgs: ', item.msgs);
        console.log('item.tasks: ', item.tasks);

        const result = {
          //_id: item._id,
          tasks: item.tasks, //item.msgs,
        };

        setConfigMessages(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });


    // OBTEM OS DADOS DAS "CATEGORIAS"
    api
      .get('category')
      .then((response) => {
        const result = response.data.categories.map((item) => {
          return { label: item.label, description: item.description };
        });
        setCategorias(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });

    // OBTEM OS DADOS DE "SERVIÇOS"
    api
      .get('service')
      .then((response) => {
        const result = response.data.services.map((item) => {
          return { name: item.name, id: item._id };
        });
        setCustomerServiceOptions(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });


  }, []);

  useEffect(() => {
    console.log('modalData.intervalValue: ', modalData.intervalValue);
  }, [modalData.intervalValue]);



  const filteredWidgets = configMessages.tasks?.filter((dadosMsgAgendada) => {
    //console.log('O titulo possui dados da pesquisa: ', dadosMsgAgendada.title.toLowerCase().includes(searchTerm.toLowerCase()));

    if (searchTerm === '') return dadosMsgAgendada;
    else if (dadosMsgAgendada.taskName.toLowerCase().includes(searchTerm.toLowerCase())) return dadosMsgAgendada;
    else if (dadosMsgAgendada.desc.toLowerCase().includes(searchTerm.toLowerCase())) return dadosMsgAgendada;
    else return false;
  });


  return (
    <div
      style={{ width: 'auto', marginTop: 20 }}
      className="flex flex-col  justify-between space-y-4"
    >
      {/* ================================================== CABEÇALHO ================================================== */}
      {/** /}<Card extra={'w-full h-full sm:overflow-auto px-6 mt-1'}>
        <header className="relative flex items-center py-4">

          {/* --------------------- BOTÃO ADD --------------------- * /}
          <button
            onClick={() => {
              setEditMsg(false);
              onOpen(modalData);
              console.log('Btn add Agendamento clicado');
            }}
            className="flex items-center justify-center rounded-full bg-brand-500 p-2 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-300 dark:text-white dark:hover:bg-brand-400 dark:active:bg-brand-200 ampliar"
            title='Adicionar mensagem'
          >
            <MdAdd />
          </button>

          {/* --------------------- CAMPO PESQUISA --------------------- * /}
          <div className="flex flex-row items-center space-x-2 ml-auto">
            <MdSearch />
            <InputField
              placeholder="Pesquisar..."
              id="campo-pesquisa"
              type="text"
              extra="w-full"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </header>
      </Card>{/**/}



      


      {/* ================================================== WIDGETS ================================================== */}

      {
        //console.log('dadosMsgAgendada: ', dadosMsgAgendada);
        <div>

          <Widget

            index={0} //{dadosMsgAgendada.id_msg}
            id_msg={0}
            taskName={'Multi-atendimentos'}
            //subtitle={dadosMsgAgendada.desc}
            intervalType={0}
            intervalValue={0}
            frequency={0}
            executionTime={0}
            categorySend={''}
            dataMsg={''}
            diaDisparo={0}

            status={true}
            icon={<MdMessage className="h-6 w-6" />}
            value={configMessages}
            handleUpdateConfigMessages={handleUpdateConfigMessages}

            abrirModal={abrirModal}
            setMessage={setMessage}
            setMediaType={setMediaType}
            setLinkArq={setLinkArq}
            setEditMsg={setEditMsg}
            setTitulo={setTitulo}
            setCategorySendState={setCategorySendState}
            setIntervalValueState={setIntervalValueState}
            setIntervalTypeState={setIntervalTypeState}
            setExecutionTimeState={setExecutionTimeState}
            setDiaDisparo={setDiaDisparo}
          />
          {isOpen && <Modal data={modalData} onClose={() => {
            onOpen(false);
            setEditMsg(false);
          }} />}
          
        </div>



      }


    </div >
  );
};

export default MessagesConfiguration;
