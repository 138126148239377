// Custom components
import { useState } from 'react';
import Toast from 'components/toast';
import api from 'api/api';

import ledOff from 'assets/img/diverse/leds/led-off.png';
import ledOnGreen from 'assets/img/diverse/leds/led-green-on.png';

const SetFlow = (props) => {
  const {
    setUrlTypebot,
    TypebotApiHost,
    listTypebots_ByCategory,
    isActive,
    setIsActive,
    categorySelect,
    flowSelect,
    setFlowSelect,
    handleSelectCategory,
    flowRespAuto,
    availableUsage,
  } = props;

  /*const [flowRespAuto, setFlowRespAuto] = useState([
    {
      id: '01',
      name: 'fluxo-teste-1',
    },
    {
      id: '02',
      name: 'fluxo-teste-2',
    },
  ]);*/


  const [statusFlowResp, setStatusFlowResp] = useState('Desativado');


  // ---------------------------------------------------------------------------------------------------------------------------




  // ---------------------------------------------------------------------------------------------------------------------------
  const handleSelectFlow = (data) => {

    const publicId = data.target.value;
    if (publicId == 'indefinido') return;

    setFlowSelect(publicId);

    const urlTypebot = TypebotApiHost + publicId;
    console.log('Url typebot: ', urlTypebot);

    setUrlTypebot(urlTypebot);

    // SE AS RESPOSTAS AUTOMATIZADAS ESTIVER ATIVADO, ENVIA ATUALIZAÇÃO DE FLUXO ESCOLHIDO
    if(isActive) updateStatusAutomation(publicId);
  };



  // ---------------------------------------------------------------------------------------------------------------------------
  /*const handleSelectCategory = (data) => {

    const nameCategory = data.target.value;
    if (nameCategory == 'indefinido') return;

    setCategorySelect(nameCategory);

    let typebots = listTypebots_ByCategory.find(dataCategory => dataCategory.category === nameCategory).typebots;

    console.log('typebots: ', typebots);
    setFlowRespAuto(typebots);
  };*/


  const handleButtonActiveDesable = async (data) => {

    const statusAutomationUpdated = await updateStatusAutomation();
    console.log('Dados do user foi atualizado:', statusAutomationUpdated);
    if (statusAutomationUpdated) setIsActive(isActive ? false : true);

  }



  // ---------------------------------------------------------------------------------------------------------------------------
  const updateStatusAutomation = async (flowSelect_) => {

    console.log('categorySelect:', categorySelect);
    console.log('flowSelect:', flowSelect);

    if (!flowSelect && !isActive || flowSelect == 'indefinido' && !isActive
      || !categorySelect && !isActive || categorySelect == 'indefinido' && !isActive) {

      Toast.fire({
        icon: 'warning',
        title: 'Um fluxo precisa ser escolhido!',
      });
      return false;
    }

    const body = {
      dataTypebot: {
        availableUsage: availableUsage,
        active: isActive ? false : true,
        categoryTypebot: categorySelect || 'indefinido',
        flowTypebot: flowSelect_ || flowSelect || 'indefinido',
      }
    };

    const updateDataUser = await api.patch(`user/updateOne`, body);


    console.log('updateDataUser:', updateDataUser.data);

    if (!updateDataUser?.statusText) return false;
    else return true;

  }// Fim updateStatusAutomation




  // ---------------------------------------------------------------------------------------------------------------------------
  /*useEffect(() => {
    handleSelectCategory(categorySelect);
  }, [categorySelect]);*/




  /* =========================================================================================================================== */
  return (
    <div className="text-base text-navy-700 px-5 dark:text-white  md:px-2">


      {/* ============================== ESTADO DO FUNCIONAMENTO ============================== */}
      <div className='flex items-center p-3 rounded-xl bg-[#d5d5d5]  dark:bg-[#243162]'>
        <img className='' src={isActive ? ledOnGreen : ledOff} title={statusFlowResp} width="20" height="20" style={{ float: 'left' }} />
        <span className="font-bold text-lg ml-2">{isActive ? 'Ativado' : 'Desativado'}</span>
      </div>


      {/* ============================== LINHA DIVISORA ============================== */}
      {/*}<hr
        className="my-3"
      />{*/}


      {/* ============================== DEFINIÇÕES DAS RESPOSTAS AUTOMATIZADAS ============================== */}
      <div className='mt-10 grid grid-cols-2 gap-3'>

        {/* ------ SELEÇÃO DA CATEGORIA ------ */}
        <div>
          <label
            for="categoryResp"
            class="text-sm font-bold text-navy-700 dark:text-white"
          >
            Categoria
          </label>
          <select
            id="categoryResp"
            class=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
            onChange={handleSelectCategory}
            value={categorySelect}
          >
            <option value="indefinido">-- Selecione uma categoria --</option>
            {listTypebots_ByCategory.map((option) => (
              <option className="dark:bg-gray-700" value={option.category}>
                {option.category}
              </option>
            ))}
          </select>
        </div>


        {/* ------ SELEÇÃO DO FLUXO ------ */}
        <div>
          <label
            for="categoryResp"
            class="text-sm font-bold text-navy-700 dark:text-white"
          >
            Fluxo
          </label>
          <select
            id="categoryResp"
            class=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
            onChange={handleSelectFlow}
            value={flowSelect}
          >
            <option value="indefinido">-- Selecione um fluxo --</option>
            {flowRespAuto.map((option) => (
              <option className="dark:bg-gray-700" value={option.publicId}>
                {option.name}
              </option>
            ))}
          </select>
        </div>


      </div>


      {/* ------ BOTÃO ATIVAR/DESATIVAR ------ */}
      <div className='mx-auto mt-20 text-center'>
        <button
          onClick={handleButtonActiveDesable}
          className={`mt-5 rounded-xl px-5 py-3 text-base font-medium text-white transition duration-200 ${isActive ? 'bg-red-500' : 'bg-green-500'} hover:bg-green-600 dark:${isActive ? 'bg-red-400' : 'bg-green-400'} dark:hover:${isActive ? 'bg-red-300' : 'bg-green-300'} dark:text-white`}
        >
          {isActive ? 'Desativar' : 'Ativar'}
        </button>
      </div>

    </div>
  );
}

export default SetFlow;
