import React, { useMemo, useState } from 'react';
import Card from 'components/card';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { useDisclosure } from '@chakra-ui/hooks';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import InputField from '../components/InputField';
import api from 'api/api';
import Toast from 'components/toast';
import Swal from 'sweetalert2';

const CheckTable = (props) => {
  const { columnsData, tableData, totalPages, currentPage, onPageChange, fetchCustomers } =
    props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dadosCampoTipo, setDadosCampoTipo] = useState('');
  const [dadosCampoTitulo, setDadosCampoTitulo] = useState('');
  const [dadosCampoId, setDadosCampoId] = useState('');
  const [modalTitle, setModalTittle] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 100;


  // ---------------------------------------------------------------------------------------------------------------------------
  // IMPLEMENTAÇÃO P/ TRABALHAR C/ AS OPÇÕES

  // Estado para armazenar as opções escolhidas
  const [opçõesEscolhidas, setOpçõesEscolhidas] = useState([]);
  // Estado para controlar o valor selecionado no select
  const [optionSelecionada, setOptionselecionada] = useState('');

  // Lista de Opções (você pode buscar isso de uma API ou de qualquer outra fonte)
  const [Options, setOptions] = useState([]);

  // Manipulador de mudança no select
  const handleSelectOptionChange = (event) => {
    //console.log('Entrou na Func: handleSelectOptionChange');

    const dadosInput = document.querySelector('#addOption');
    //console.log('dadosInput.value: ', dadosInput.value);

    var novaOption = dadosInput.value;

    //[novaOption] = Options.filter(c => c.description == novaOption);
    const [jáAdicionada] = opçõesEscolhidas.filter(c => c == novaOption);
    //console.log('jáAdicionada: ', jáAdicionada);

    // Adiciona a nova "opção" à lista de "Opções" escolhidas
    if (!jáAdicionada) setOpçõesEscolhidas([...opçõesEscolhidas, novaOption]);

    // Limpa o campo de entrada p/ o nome da "opção" 
    setOptionselecionada('');
    dadosInput.value = '';
  };

  // Manipulador de clique para remover uma categoria escolhida
  const handleRemoveOptions = (option) => {
    const novasOptions = opçõesEscolhidas.filter(c => c !== option);
    setOpçõesEscolhidas(novasOptions);
  };



  // =============================================================================================================================
  const openNewForm = () => {
    
    setModalTittle('Adicionar');

    setDadosCampoTitulo('');
    setDadosCampoTipo('text');
    setOpçõesEscolhidas([]);

    onOpen();
  };
  
  const openEditForm = (value) => {
    console.log('Dados carregados p/ edição: ', value);
    setModalTittle('Editar');
    setDadosCampoTitulo(value.label);
    setDadosCampoTipo(value.type);
    setDadosCampoId(value.id);
    setOpçõesEscolhidas(value.options)

    onOpen();
  };

  const submitForm = () => {
    setIsLoading(true);

    const value = dadosCampoTipo == 'select' ? opçõesEscolhidas[0] :  '';
    api
      .post('dynamic-fields', {         
        label: dadosCampoTitulo,
        type: dadosCampoTipo,
        options: opçõesEscolhidas,
        value: value,
      })
      .then((response) => {
        console.log('response.data: ', response.data);

        Toast.fire({
          icon: 'success',
          title: 'Seu Campo foi criado com sucesso!',
        });
        onClose();
        fetchCustomers(currentPage);

        setIsLoading(false);
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: 'Erro ao criar Campo, tente novamente mais tarde.',
        });
      });
  };

  const editService = () => {
    setIsLoading(true);

    const value = dadosCampoTipo == 'select' ? opçõesEscolhidas[0] :  '';
    api
      .patch('dynamic-fields/' + dadosCampoId, {
        label: dadosCampoTitulo,
        type: dadosCampoTipo,
        options: opçõesEscolhidas,
        value: value,
      })
      .then((response) => {
        console.log(response.data);

        Toast.fire({
          icon: 'success',
          title: 'Seu Campo foi editado com sucesso!',
        });
        onClose();
        fetchCustomers(currentPage);
        /*setTimeout(() => {
          window.location.reload();
        }, 1500);*/

        setIsLoading(false);
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: 'Erro ao editar Campo, tente novamente mais tarde.',
        });
      });
  };


  const confirmDeleteService = (id) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Não será possivel reverter essa exclusão!',
      icon: 'warning',
      position: 'top',
      showCancelButton: true,
      confirmButtonColor: '#422afb',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      customClass: 'dark:bg-[#00205a] dark:text-[#eaf2f9]',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteService(id);
      }
    });
  };

  const deleteService = (id) => {
    setIsLoading(true);
    api
      .delete('dynamic-fields/' + id)
      .then((response) => {
        console.log(response.data);

        Toast.fire({
          icon: 'success',
          title: 'Seu serviço foi deletado com sucesso!',
        });

        fetchCustomers(currentPage);
        /*setTimeout(() => {
          window.location.reload();
        }, 1500);*/

        setIsLoading(false);
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: 'Erro ao excluir serviço, tente novamente mais tarde.',
        });
      });
  };

  return (
    <Card extra={'w-full h-full sm:overflow-auto px-6 mt-1'}>
      <header className="relative flex items-center justify-between pt-4">
        <button
          onClick={() => {
            openNewForm();
          }}
          className="flex items-center justify-center rounded-full bg-brand-500 p-2 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          <MdAdd />
        </button>
      </header>
      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max _!max-w-[85%] top-[17vh] md:top-[17vh] md:min-w-[700px]">
          <ModalBody>
            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[700px] flex !z-[1004]">
              <h1 className="mb-[20px] text-2xl font-bold">
                {modalTitle} campo cliente
              </h1>
              <div className="grid gap-4 grid-cols-1  md:grid-cols-2">

                {/** /}<InputField
                  label="Tipo"
                  placeholder="Ex: Select"
                  id="service"
                  type="text"
                  extra="w-full"
                  value={dadosCampoTipo}
                  onChange={(e) => setDadosCampoTipo(e.target.value)}
                />{/**/}

                <div className={`w-full`}>
                  <label
                    for="field.tipo"
                    class="text-sm font-bold text-navy-700 dark:text-white"
                  >
                    Tipo
                  </label>
                  <select
                    id="field.tipo"
                    class=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:!border-white/10"
                    value={dadosCampoTipo}
                    onChange={(e) => setDadosCampoTipo(e.target.value)}
                  >
                    <option className="dark:bg-gray-700" value="text">ENTRADA DE TEXTO</option>
                    <option className="dark:bg-gray-700" value="select">SELEÇÃO DE OPÇÕES</option>
                  </select>
                </div>

                <InputField
                  label="Titulo"
                  placeholder="Ex: Nome"
                  id="titulo"
                  type="text"
                  extra="w-full"
                  value={dadosCampoTitulo}
                  onChange={(e) => setDadosCampoTitulo(e.target.value)}
                />


                {/* INTERFACE P/ ADICIONAR E REMOVER OPÇÕES */}
                {/*<div className={`${dadosCampoTipo == 'select' ? '' : 'hidden'}`}>*/}
                  <div className={`${dadosCampoTipo == 'select' ? '' : 'hidden'}`}>

                    <div
                      class="text-sm font-bold text-navy-700 _text-center dark:text-white"
                    >
                      Adicionar opção
                    </div>

                    <div className='w-full flex'>{/* grid grid-cols-2 */}

                      <InputField
                        //label="Adicionar opção"
                        placeholder="Ex: Opção 1"
                        id="addOption"
                        type="text"
                        extra="w-full"
                        defaultValue={optionSelecionada}
                      //onChange={(e) => setDadosCampoTitulo(e.target.value)}
                      />

                      <button
                        //htmlFor="addOption"
                        className="flex items-center justify-center rounded-full bg-green-500 ml-2 p-2 w-[35px] h-[35px] text-[20px] my-auto text-white transition duration-200 hover:cursor-pointer hover:bg-green-600 dark:bg-green-600 dark:text-white dark:hover:bg-green-500"
                        onClick={handleSelectOptionChange}
                      >
                        <MdAdd />
                      </button>

                    </div>
                  </div>


                  {/* LISTA DE OPÇÕES ESCOLHIDAS */}
                  <div className={`${dadosCampoTipo == 'select' ? '' : 'hidden'}`}>

                    <div
                      class="text-sm font-bold text-navy-700 _text-center dark:text-white"
                    >
                      Lista de opções
                    </div>

                    <div className={`smallScrollbar mt-2 border bg-[#0000000d] border-gray-200 dark:!border-white/10 rounded-xl p-2 h-[70px] overflow-auto flex flex-wrap`}>{/* grid grid-cols-1 gap-2 md:grid-cols-2 */}

                      {opçõesEscolhidas.map((option, index) => (

                        <div key={index} className='gap-1 mx-2 mt-1' style={{ display: '-webkit-box' }}>
                          {console.log('option: ', option)}
                          {/*<div className='w-5 h-5 rounded-[100%] _bg-[#ffce8c]' style={{ background: option.label }}></div>*/}
                          <div>{option}</div>

                          <button
                            onClick={() => {
                              handleRemoveOptions(option)
                            }}
                            className="text-[20px] text-white text-gray-500 hover:cursor-pointer dark:text-gray-300 ampliar"
                            title='Excluir option'
                          >
                            <MdDelete />
                          </button>
                        </div>
                      ))}

                    </div>
                  </div>
                {/*</div>*/}

              </div>
              <div className="mt-10 flex justify-end gap-2">
                <button
                  onClick={onClose}
                  className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                >
                  Cancelar
                </button>
                <button
                  onClick={() => {
                    modalTitle === 'Adicionar' ? submitForm() : editService()
                  }}
                  className="linear rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Salvar
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'Tipo') {
                      data = (
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value == 'text' ? 'ENTRADA DE TEXTO' : (cell.value == 'select' ? 'SELEÇÃO DE OPÇÕES' : cell.value)}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'Titulo') {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'Opções') {
                      data = (
                        <div className="flex items-center">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value.length > 0 ?
                              cell.value.map((option) => {

                                return `${option} / `;
                              })
                              : '-- // --'}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'Id') {
                      data = (
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'Ação') {
                      data = (
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() => openEditForm(row.original)}
                            className="flex items-center justify-center rounded-xl bg-brand-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                          >
                            <MdEdit />
                          </button>
                          <button
                            onClick={() => {
                              confirmDeleteService(row.original.id);
                            }}
                            className="flex items-center justify-center rounded-xl bg-red-500 p-1 text-2xl text-white transition duration-200 hover:cursor-pointer hover:bg-red-700 active:bg-brand-700 dark:bg-red-500 dark:text-white dark:hover:bg-red-700 dark:active:bg-red-200"
                          >
                            <MdDelete />
                          </button>
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pt-[14px] pb-[16px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="flex justify-center mt-4 mb-4">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-gray-400 px-4 py-2 rounded-l-xl disabled:bg-gray-200  dark:bg-gray-700 dark:disabled:bg-gray-600"
          >
            Anterior
          </button>
          <h2 className="p-2 px-4" id="page">
            {currentPage}
          </h2>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="bg-gray-400 px-4 py-2 rounded-r-xl disabled:bg-gray-200  dark:bg-gray-700 dark:disabled:bg-gray-600"
          >
            Próximo
          </button>
        </div>
      </div>
    </Card>
  );
};

export default CheckTable;
