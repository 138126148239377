import { useEffect, useState, useRef } from 'react';
import Widget from './components/Widget';
import { IoMdHome } from 'react-icons/io';
import { MdBarChart, MdDashboard, MdMessage, MdSearch, MdAdd, MdInsertEmoticon } from 'react-icons/md';
import api from 'api/api';
import InputField from './components/InputField';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';

import TextField from './components/TextField';
import Toast from 'components/toast';
import MessageTriggers from './components/MessageTriggers';

const MessagesConfiguration = () => {
  const [configMessages, setConfigMessages] = useState({});

  const handleUpdateConfigMessages = (data) => {

    const result = {
      _id: data._id,
      /*fiveDaysBefore: item.fiveDaysBefore,
      threeDaysBefore: item.threeDaysBefore,
      oneDayBefore: item.oneDayBefore,
      EndDay: item.EndDay,
      oneDayAfter: item.oneDayAfter,*/
      msgs: data.msgs,
    };

    setConfigMessages(result);
  };

  // ----------------------------------------------------------------------------------------------------------------------

  // PARA CONTROLE DO MODAL
  const { isOpen, onOpen, onClose } = useDisclosure();

  const abrirModal = (data) => {
    console.log('Entrou na Func abrirModal');
    console.log('data: ', data);
    //console.log('editMsg: ', editMsg);

    setModalData(data);
    onOpen(true);

    // SE FOR UMA MSG DO TIPO MEDIA, ABRE O CAMPO DO LINK
    if (data?.dadosMedia?.type === 'image' || data?.dadosMedia?.type === 'video') {

      setTimeout(() => {

        document.querySelector('#pai-link-mulmidia').classList.remove('pb-0');
        document.querySelector('#pai-link-mulmidia').classList.add('pb-[96px]');
      }, 500);
    }
  };

  const [mediaType, setMediaType] = useState('');
  const [linkArq, setLinkArq] = useState('');

  const [editMsg, setEditMsg] = useState(false);

  const [modalData, setModalData] = useState({
    //id_msg: '',
    title: '',
    desc: '',
    msg: '',
    diaDisparo: '',
    horarioDisparo: '',
    status: true,
    dadosMedia: {
      type: mediaType,
      media: linkArq,
    },
    selectValue: 'text',
  });

  const [isLoading, setIsLoading] = useState(false);

  // Define o conteúdo para o titulo da textarea
  const [tituloCampoTxtArea, setTituloCampoTxtArea] = useState('Mensagem');

  // Define a referência para o textarea
  const textFieldRef = useRef(); // Referência para o TextField

  // Define o estado para controlar se o seletor de emoticons está aberto ou não
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  // Define o estado para armazenar a mensagem atual no textarea
  const [message, setMessage] = useState('');

  // Define o estado para controlar a classe do seletor de emoticons
  const [emojiPickerClass, setEmojiPickerClass] = useState('hidden');

  // Define a função para alternar a visibilidade do seletor de emoticons
  const toggleEmojiPicker = () => {
    //console.log('Dados conj-emoticons: ', document.querySelector('#conj-emoticons').classList.contains('display') );
    //const conj_emoticons_display = document.querySelector('#conj-emoticons') ? document.querySelector('#conj-emoticons').classList.contains('display') : false;
    console.log('Display conj-emoticons: ', document.querySelector('#conj-emoticons')?.classList.contains('aparecer-pra-baixo'));
    if (document.querySelector('#conj-emoticons')?.classList.contains('aparecer-pra-baixo')) { //isEmojiPickerOpen
      setEmojiPickerClass('');
      /*setTimeout(() => {
        setIsEmojiPickerOpen(false);
      }, 1_000);*/ // Ajuste este valor para corresponder à duração da transição CSS
    } else {
      setIsEmojiPickerOpen(true);

      const element = document.querySelector('#conj-emoticons');
      element.style['margin-top'] = '-40%';
      element.style['display'] = '';
      //const marginCustom = Number('-' + $(seletor).css('height').replace('px', '')) - 20 + 'px';
      //element.style['margin-top'] = marginCustom;

      setEmojiPickerClass('aparecer-pra-baixo');
    }
  };

  // Define a função para lidar com o clique em um emoticon
  // Adiciona o emoticon clicado na posição do cursor no textarea e fecha o seletor de emoticons
  const handleEmojiClick = (emoji) => {
    if (textFieldRef.current) {
      const { selectionStart, value } = textFieldRef.current; //.querySelector('textarea'); //textareaRef.current;
      setMessage(value.slice(0, selectionStart) + emoji + value.slice(selectionStart));
      //setIsEmojiPickerOpen(false);
    }
  };

  // Define a função para lidar com a mudança no textarea
  // Atualiza a mensagem com o valor atual do textarea e mantém o foco no textarea
  const handleTextChange = (event) => {
    setMessage(event.target.value);
    //textareaRef.current.focus();
  };

  // DEFINE O TIPO DE MENSAGEM DE ACORDO COM A OPÇÃO ESCOLHIDA NO SELECT
  const handleSelectTipoMsgChange = (e) => {

    const tipoMsg = e.target.value;
    console.log('Select tipo msg: ', tipoMsg);

    if (tipoMsg === 'text') {

      // DEFINE O TITULO DO CAMPO 'TEXTAREA'
      setTituloCampoTxtArea('MENSAGEM');

      // RESETA OS DADOS P/ MSG DE TEXTO
      setMediaType('text');
      setLinkArq('');

      // DEFINE OS DADOS P/ MSG DE TEXTO
      setModalData((prevState) => ({
        ...prevState,
        dadosMedia: {
          type: tipoMsg,
          media: modalData?.dadosMedia?.media || '',
        },
      }));

      //document.querySelector('#linkMulmidia').classList.add('hidden');

      const element = document.querySelector('#linkMulmidia');
      //element.classList.remove('aparecer-pra-baixo');
      document.querySelector('#pai-link-mulmidia').classList.add('pb-0');
      document.querySelector('#pai-link-mulmidia').classList.remove('pb-[96px]');

      //element.classList.add('mt-[-40%]');
      //element.classList.remove('mt-0');
    }
    else if (tipoMsg === 'image' || tipoMsg === 'video') {

      // DEFINE O TITULO DO CAMPO 'TEXTAREA'
      setTituloCampoTxtArea('LEGENDA');

      // DEFINE OS DADOS P/ MSG DE MEDIA
      setModalData((prevState) => ({
        ...prevState,
        dadosMedia: {
          type: tipoMsg,
          media: modalData?.dadosMedia?.media || '',
        },
      }));

      setMediaType(tipoMsg);
      //setLinkArq(false);

      //document.querySelector('#linkMulmidia').classList.remove('hidden');

      const element = document.querySelector('#linkMulmidia');
      /*element.style['margin-top'] = '-40%';
      element.style['display'] = '';*/

      document.querySelector('#pai-link-mulmidia').classList.remove('pb-0');
      document.querySelector('#pai-link-mulmidia').classList.add('pb-[96px]');
      //element.classList.add('aparecer-pra-baixo');

      //element.classList.add('mt-0');
      //element.classList.remove('mt-[-40%]');
    }
  };

  const tipoMsgSelect = useRef();

  const editConfigMessages = (editMsg) => {
    setIsLoading(true);

    let body = configMessages;
    //body[type] = modalData.value;

    // VERIFICA SE É UMA EDIÇÃO OU ADIÇÃO DE UMA MSG
    if (editMsg !== false) {
      body.msgs[editMsg] = modalData;
      setEditMsg(false);
    }
    else body.msgs.push(modalData);

    console.log('body: ', body);
    api
      .patch('MessageConfigs/' + body._id, body)
      .then((response) => {
        Toast.fire({
          icon: 'success',
          title: 'Sua mensagem foi Adicionada com sucesso!',
        });
        onClose();
        setTimeout(() => {
          console.log(response.data);
          handleUpdateConfigMessages(response.data);
        }, 1000);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });
  };// Fim Func editConfigMessages


  // PARA CONTROLE DO CAMPO DE PESQUISA
  // 1. Crie um novo estado para armazenar o valor do campo de pesquisa
  const [searchTerm, setSearchTerm] = useState('');

  // 2. Atualize esse estado sempre que o valor do campo de pesquisa mudar
  const handleSearchChange = (event) => {
    //console.log('Dados da pesquisa: ', event.target.value);
    setSearchTerm(event.target.value);
  };

  // ----------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    api
      .get('MessageConfigs')
      .then((response) => {
        const item = response.data;

        //console.log('item.msgs: ', item.msgs);

        const result = {
          _id: item._id,
          /*fiveDaysBefore: item.fiveDaysBefore,
          threeDaysBefore: item.threeDaysBefore,
          oneDayBefore: item.oneDayBefore,
          EndDay: item.EndDay,
          oneDayAfter: item.oneDayAfter,*/
          msgs: item.msgs,
        };

        setConfigMessages(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });
  }, []);

  useEffect(() => {
    console.log('editMsg: ', editMsg);
  }, [editMsg]);

  const checkStatus = (type) => {
    return configMessages[type] !== '';
  };



  const filteredWidgets = configMessages.msgs?.filter((dadosMsg, index) => {
    //console.log('dadosMsg: ', dadosMsg);
    //console.log('index: ', index);
    //console.log('searchTerm: ', searchTerm);
    //console.log('O titulo possui dados da pesquisa: ', dadosMsg.title.toLowerCase().includes(searchTerm.toLowerCase()));

    if (searchTerm === '') return dadosMsg;
    else if (dadosMsg.title.toLowerCase().includes(searchTerm.toLowerCase())) return dadosMsg;
    else if (dadosMsg.desc.toLowerCase().includes(searchTerm.toLowerCase())) return dadosMsg;
    /*else if (dadosMsg.msg.toLowerCase().includes(searchTerm.toLowerCase())) return dadosMsg;
    else if (dadosMsg.diaDisparo.toLowerCase().includes(searchTerm.toLowerCase())) return dadosMsg;
    else if (dadosMsg.horarioDisparo.toLowerCase().includes(searchTerm.toLowerCase())) return dadosMsg;
    else if (dadosMsg.dadosMedia?.type.toLowerCase().includes(searchTerm.toLowerCase())) return dadosMsg;
    else if (dadosMsg.dadosMedia?.media.toLowerCase().includes(searchTerm.toLowerCase())) return dadosMsg;
    else if (dadosMsg.status.toString().toLowerCase().includes(searchTerm.toLowerCase())) return dadosMsg;*/
    else return false;
  });


  return (
    <div
      style={{ width: 'auto', marginTop: 20 }}
      className="flex flex-col  justify-between space-y-4"
    >
      {/* ================================================== CABEÇALHO ================================================== */}
      <Card extra={'w-full h-full sm:overflow-auto px-6 mt-1'}>
        <header className="relative flex items-center py-4">

          {/* --------------------- BOTÃO ADD --------------------- */}
          <button
            onClick={() => {
              setEditMsg(false);
              onOpen(modalData);
              console.log('Btn add msg de Vencimento clicado');
            }}
            className="flex items-center justify-center rounded-full bg-brand-500 p-2 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-300 dark:text-white dark:hover:bg-brand-400 dark:active:bg-brand-200 ampliar"
            title='Adicionar mensagem'
          >
            <MdAdd />
          </button>

          {/* --------------------- CAMPO PESQUISA --------------------- */}
          <div className="flex flex-row items-center space-x-2 ml-auto">
            <MdSearch />
            <InputField
              placeholder="Pesquisar..."
              id="campo-pesquisa"
              type="text"
              extra="w-full"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </header>
      </Card>



      {/* ==================================================== CONSTROI O MADAL ==================================================== */}
      {<Card extra="!flex-row flex-grow items-center rounded-[20px]">

        <Modal /*data={data}*/ isOpen={isOpen} onClose={onClose} className="!z-[1010]">
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="!z-[1002] !m-auto !w-max min-w-auto _!max-w-[85%] top-[12vh] sm:top-[12vh] sm:min-w-[700px]">
            <ModalBody className=''>
              <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[700px] flex !z-[1004] max-h-[450px] overflow-y-auto  md:max-h-[600px]">

                {/* ---------------------------------------- CAMPO TITULO ---------------------------------------- */}
                {/*<h1 className="mb-[20px] text-xl font-bold">{modalData.title}</h1>*/}
                <div className="my-[20px]">
                  <div>
                    <label
                      htmlFor={''}
                      className="ml-0 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      TITULO
                    </label>
                  </div>

                  <InputField
                    placeholder="Mensagem 5 dias antes do vencimento..."
                    id="customer.name"
                    type="text"
                    extra="w-full"
                    value={modalData.title}
                    onChange={(e) => {
                      setModalData((prevState) => ({
                        ...prevState,
                        title: e.target.value,
                      }));
                    }}
                  />
                </div>

                {/* ---------------------------------------- CAMPO DESCRIÇÃO ---------------------------------------- */}
                <div className="my-[20px] mb-8">
                  <div>
                    <label
                      htmlFor={''}
                      className="ml-0 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      DESCRIÇÃO
                    </label>
                  </div>

                  <InputField
                    placeholder="Mensagem que será enviada 5 dias antes do vencimento..."
                    id="customer.name"
                    type="text"
                    extra="w-full"
                    value={modalData.desc}
                    onChange={(e) => {
                      setModalData((prevState) => ({
                        ...prevState,
                        desc: e.target.value,
                      }));
                    }}
                  />
                </div>

                {/* -------------------------------- LINHA DE DIVISÃO -------------------------------- */}
                <hr className="border-gray-200 dark:!border-white/20 mb-4 mt-5" />

                {/* -------------------------------- CAMPO LINK DO ARQ MULTIMIDIA -------------------------------- */}
                <div id='pai-link-mulmidia' className='overflow-hidden transition-all duration-1000 pb-0'>
                  <div id='linkMulmidia' className="_aparecer-pra-baixo-default transition-all duration-1000" style={{ /*display: 'none',*/ }} >
                    <div className='text-center'>
                      <label
                        htmlFor={''}
                        className="ml-0 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        LINK ARQUIVO MULTIMIDIA
                      </label>
                    </div>

                    <InputField
                      placeholder="http://imagem.jpg ..."
                      id="inputLinkMulmidia"
                      type="text"
                      extra="w-full"
                      value={linkArq}
                      onChange={(e) => {
                        setModalData((prevState) => ({
                          ...prevState,
                          dadosMedia: {
                            type: modalData.dadosMedia.type,
                            media: e.target.value,
                          },
                        }));

                        setLinkArq(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* ----------------------------------- CAMPO 'TEXTAREA' DA MSG ----------------------------------- */}
                <div id="contentMsg" className="">
                  <TextField
                    label={tituloCampoTxtArea}
                    textFieldRef={textFieldRef}
                    value={message}
                    onChange={(e) => {
                      setModalData((prevState) => ({
                        ...prevState,
                        msg: e.target.value,
                      }));
                      handleTextChange(e);
                    }
                    }
                  />

                  {/* Renderiza o textarea com a mensagem atual e o manipulador de mudança */}
                  {/*<textarea ref={textareaRef} value={message} onChange={handleTextChange} />*/}

                  <div className='flex items-center'>

                    {/* Renderiza o botão para abrir/fechar o seletor de emoticons */}
                    <button onClick={toggleEmojiPicker}>
                      <div className='p-2 h-[50px]'>
                        <MdInsertEmoticon className="h-8 w-8 transition-all hover:h-9 hover:w-9" />
                      </div>
                    </button>

                    {/* SELECTE DO TIPO DE MENSAGEM */}
                    <select
                      onChange={handleSelectTipoMsgChange}
                      id="selectTipoMsg"
                      class="ml-auto mr-2 flex w-auto items-center justify-center rounded-xl border bg-white/0 p-1 text-sm outline-none"
                      ref={tipoMsgSelect}
                      value={mediaType} //{modalData.selectValue}
                    >
                      <option className="dark:bg-gray-700" value="text">Mens. De texto</option>
                      <option className="dark:bg-gray-700" value="image">Mens. De imagem</option>
                      <option className="dark:bg-gray-700" value="video">Mens. De vídeo</option>
                      {/* Adicione mais opções conforme necessário */}
                    </select>

                  </div>

                  {/* Se o seletor de emoticons estiver aberto, renderiza os emoticons */}
                  <div className='overflow-hidden'>
                    {isEmojiPickerOpen && (
                      <div id='conj-emoticons' className={`rounded-xl bg-[#ffffff17] p-2 w-auto h-auto aparecer-pra-baixo-default ${emojiPickerClass}`}>
                        {/* Para cada emoticon, renderiza um botão que, quando clicado, adiciona o emoticon à mensagem */}
                        {['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇', '🙂', '🙃',
                          '😉', '😌', '😍', '🥰', '😘', '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐', '🤓', '😎', '🤩',
                          '🥳', '😕', '🙁', '🥺', '😭'].map((emoji) => (
                            <button key={emoji} className='p-[2px] transition-all' onClick={() => handleEmojiClick(emoji)}>
                              {emoji}
                            </button>
                          ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* -------------------------------------- OUTROS CAMPOS P/ ENTRADA -------------------------------------- */}
                <div className="flex flex-row items-center mt-10 mx-auto _space-x-2">


                  {/* ------------------------------ CAMPO P/ DEFINIR DIA DO DISPARO ------------------------------ */}
                  <div className="text-center px-3">
                    <div>
                      <label
                        htmlFor={''}
                        className="ml-0 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        DIA DO DISPARO
                      </label>
                    </div>

                    <InputField
                      placeholder="-5..."
                      id=""
                      type="number"
                      extra="w-full"
                      value={modalData.diaDisparo}
                      onChange={(e) => {
                        setModalData((prevState) => ({
                          ...prevState,
                          diaDisparo: e.target.value,
                        }));
                      }}
                    />
                  </div>


                  {/* ----------------------------- CAMPO P/ DEFINIR HORARIO DO DISPARO ----------------------------- */}
                  <div className="text-center px-3">
                    <div>
                      <label
                        htmlFor={''}
                        className="ml-0 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        HORÁRIO DO DISPARO
                      </label>
                    </div>

                    <InputField
                      placeholder="12:00..."
                      id=""
                      type="time"
                      extra="w-full"
                      value={modalData.horarioDisparo}
                      onChange={(e) => {
                        setModalData((prevState) => ({
                          ...prevState,
                          /*id_msg: modalData.id_msg,
                          title: modalData.title,
                          desc: modalData.desc,
                          msg: modalData.msg,
                          diaDisparo: modalData.diaDisparo,*/
                          horarioDisparo: e.target.value,
                          //dadosMedia: modalData.dadosMedia,
                        }));
                      }}
                    />
                  </div>

                </div>


                {/* --------------------------- EXIBE LISTA DE FRASES/PALAVRAS GATILHOS --------------------------- */}
                <MessageTriggers />

                {/* ---------------------------------------- EXIBE BOTÕES ---------------------------------------- */}
                <div className="mt-10 flex justify-end gap-2">
                  <button
                    onClick={onClose}
                    className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={() => editConfigMessages(editMsg)}
                    className="linear rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                  >
                    Salvar
                  </button>
                </div>

              </Card>
            </ModalBody>
          </ModalContent>
        </Modal>

      </Card>}




      {/* ================================================== WIDGETS ================================================== */}

      {
        //configMessages.msgs?.map((dadosMsg, index) => {
        filteredWidgets?.map((dadosMsg, index) => {

          //console.log('dadosMsg: ', dadosMsg);

          return (
            <div>
              <Widget

                key={index}
                index={index} //{dadosMsg.id_msg}
                id_msg={dadosMsg.id_msg}
                icon={<MdMessage className="h-6 w-6" />}
                title={dadosMsg.title}
                subtitle={dadosMsg.desc}
                diaDisparo={dadosMsg.diaDisparo}
                horarioDisparo={dadosMsg.horarioDisparo}
                msg={dadosMsg.msg}
                status={dadosMsg.status}
                value={configMessages}
                handleUpdateConfigMessages={handleUpdateConfigMessages}

                dadosMedia={dadosMsg.dadosMedia}
                abrirModal={abrirModal}
                setMessage={setMessage}
                setMediaType={setMediaType}
                setLinkArq={setLinkArq}
                setEditMsg={setEditMsg}
              />
              {isOpen && <Modal data={modalData} onClose={() => {
                onOpen(false);
                setEditMsg(false);
              }} />}
            </div>

          );// Fim Return
        
        })// Fim Map filteredWidgets

      }
      

    </div >
  );
};

export default MessagesConfiguration;
