import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navbar from '../../components/navbar';
import Sidebar from '../../components/sidebar';
import Footer from '../../components/footer/Footer';
import routes from '../../routes.js';
import api from '../../api/api';

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [name, setName] = React.useState('');
  const [currentRoute, setCurrentRoute] = React.useState('Main Dashboard');

  React.useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < 1900 ? setOpen(false) : setOpen(true), //window.innerWidth < 1200
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  React.useEffect(() => {
    // DEFINE CLASS NO "BODY"
    document.body.classList.add('normalScrollbar');

    const userInfo = localStorage.getItem('USER_DATA');
    userInfo != '' ? setName(JSON.parse(userInfo).name) : setName('');
    api
      .get('health-check/auth')
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        localStorage.removeItem('AUTH_TOKEN');
        window.location.href = '/';
      });
  }, []);

  const getActiveRoute = (routes) => {
    let activeRoute = 'Main Dashboard';
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + '/' + routes[i].path,
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = 'ltr';
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => {/*setOpen(false)*/
        try {
          document.querySelector('#sidebar_id').classList.add('-translate-x-96', 'ml-[-315px]');//-96px
        } catch {}
        
      }
      } />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-0 p-0 h-full flex-none transition-all md:mx-[12px] md:pr-2 `} //xl:ml-[313px]
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              name={name}
              onOpenSidenav={() => {
                setOpen(true);
                try {
                  document.querySelector('#sidebar_id').classList.remove('-translate-x-96', 'ml-[-315px]');//-96px
                } catch {}
                
              }}
              logoText={'ClickDigital'}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] md:p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
