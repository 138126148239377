import api from 'api/api';
import Toast from 'components/toast';
import api_typebot from 'api/api_typebot';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import SetFlow from './components/setFlow';
import TestFlow from './components/testFlow';

const AutomatedResponses = () => {

  const [urlTypebot, setUrlTypebot] = useState('https://apitypebot.ckdigital.xyz/fluxo-teste-1');
  const [foldersTypebot, setFoldersTypebot] = useState([]);
  const [listTypebots_ByCategory, setListTypebots_ByCategory] = useState([]);
  let listTypebots_ByCategory2 = [];
  const TypebotApiHost = 'https://apitypebot.ckdigital.xyz/';
  const [isActive, setIsActive] = useState(true);
  const [categorySelect, setCategorySelect] = useState();
  const [flowSelect, setFlowSelect] = useState();
  const [availableUsage, setAvailableUsage] = useState(false);


  // ---------------------------------------------------------------------------------------------------------------------------
  const handleSelectCategory = async (data) => {

    const nameCategory = data.target.value;
    if (nameCategory == 'indefinido') return false;
    console.log('listTypebots_ByCategory[0]:', listTypebots_ByCategory[0]);

    let listTypebots = listTypebots_ByCategory;
    if (!listTypebots_ByCategory[0]) listTypebots = listTypebots_ByCategory2;

    setCategorySelect(nameCategory);
    console.log('listTypebots:', listTypebots);

    let typebots = listTypebots.find(dataCategory => dataCategory.category === nameCategory) //.typebots;
    //console.log('typebots(antes): ', typebots);
    if (typebots?.typebots) typebots = typebots.typebots;

    //console.log('typebots: ', typebots);
    setFlowRespAuto(typebots);
    return true;
  };

  const [flowRespAuto, setFlowRespAuto] = useState([
    {
      id: '01',
      name: 'fluxo-teste-1',
    },
    {
      id: '02',
      name: 'fluxo-teste-2',
    },
  ]);


  // ---------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    //console.log('listTypebots_ByCategory(Antes):', listTypebots_ByCategory);

    const fetchData = async () => {
      await fetchTypebots();
      getStatusAutomation();
    };

    fetchData();

  }, []);

  /*seEffect(() => {
    console.log('listTypebots_ByCategory(Depois):', listTypebots_ByCategory);
    getStatusAutomation();
  }, [listTypebots_ByCategory]);*/

  const fetchTypebots = async () => {
    console.log('Entrou na func: fetchTypebots');

    try {
      // PEGA A LISTA DE PASTAS(CATEGORIAS)
      const responseFolders = await api_typebot.get('folders?workspaceId=clug652to00011l4xsv66iuio&parentFolderId=cluotqyjm0008pwtfh01na2qo');
      const foldersTypebot = responseFolders.data.folders;
      setFoldersTypebot(foldersTypebot);
      //console.log('Categorias typebot:', foldersTypebot);

      // OBTEM O CONTEÚDO DE CADA PASTA DO TYPEBOT
      const contentFolders = [];
      //for (let i = 0; i < foldersTypebot.length; i++) {
      const foldersPromises = foldersTypebot.map(async (dataFolder_, i) => {
        const dataFolder = foldersTypebot[i];
        const folderId = dataFolder.id;
        const folderName = dataFolder.name;

        contentFolders.push({
          category: folderName,
          typebots: [],
        });

        const responseTypebots = await api_typebot.get(`typebots?workspaceId=clug652to00011l4xsv66iuio&folderId=${folderId}`);
        const typebots_Small_Infos = responseTypebots.data.typebots;
        //console.log('typebots_Small_Infos:', typebots_Small_Infos);

        const typebotPromises = typebots_Small_Infos.map(async (typebotInfo) => {
          const responseTypebot = await api_typebot.get(`typebots/${typebotInfo.id}`);
          const typebot_Data = responseTypebot.data.typebot;
          //console.log('typebot_Data:', typebot_Data);
          contentFolders[i].typebots.push(typebot_Data);
        });

        await Promise.all(typebotPromises);

      });

      await Promise.all(foldersPromises);

      console.log('Typebots por categoria: ', contentFolders);
      // DEFINE A LISTA DE TYPEBOTS
      setListTypebots_ByCategory(contentFolders);
      listTypebots_ByCategory2 = contentFolders;
      return true;

    } catch (error) {
      console.error('Erro ao obter Typebots:', error);
      Toast.fire({
        icon: 'error',
        title: 'Erro ao obter Typebots, tente novamente mais tarde.',
      });
      return false;
    }
  };// Fim fetchTypebots



  const getStatusAutomation = async () => {

    api.get(`user/dadosUser`)
      .then( async(response) => {

        const dataUser = response.data;
        console.log('Dados user:', dataUser);

        setAvailableUsage(dataUser.dataTypebot.availableUsage);
        setIsActive(dataUser.dataTypebot.active);
        setUrlTypebot(TypebotApiHost + dataUser.dataTypebot.flowTypebot);

        //setCategorySelect(dataUser.dataTypebot.categoryTypebot);
        
        const isSetCategory = await handleSelectCategory({
          target: { value: dataUser.dataTypebot.categoryTypebot }
        })

        if(isSetCategory) setFlowSelect(dataUser.dataTypebot.flowTypebot);

        //setIsLoading(false);
      })
      .catch((error) => {
        console.error('Erro ao obter estado das respostas automatizadas: ', error);
        Toast.fire({
          icon: 'error',
          title: 'Erro ao obter estado das respostas automatizadas, tente novamente mais tarde.',
        });
      });

  }// Fim getStatusAutomation




  // ===========================================================================================================================
  return (
    <div
      className="mt-5 grid grid-cols-1 gap-3  md:grid-cols-2"
    >

      <SetFlow
        setUrlTypebot={setUrlTypebot}
        TypebotApiHost={TypebotApiHost}
        listTypebots_ByCategory={listTypebots_ByCategory}
        isActive={isActive}
        setIsActive={setIsActive}
        categorySelect={categorySelect}
        setCategorySelect={setCategorySelect}
        flowSelect={flowSelect}
        setFlowSelect={setFlowSelect}
        handleSelectCategory={handleSelectCategory}
        flowRespAuto={flowRespAuto}
        setFlowRespAuto={setFlowRespAuto}
        availableUsage={availableUsage}
      />

      <TestFlow
        urlTypebot={urlTypebot}
      />

    </div>
  );
};

export default AutomatedResponses;
