import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://typebot.ckdigital.xyz/api/v1',
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json';
    const token = 'rU4WmBVGqdvgLq8821RKMHLF';

    config.headers['Authorization'] = `Bearer ${token}`;

    return config;
  },
  (error) => {
    console.error('Erro no acesso ao Typebot(config): ', error);
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('Erro no acesso ao Typebot(response): ', error);
    return Promise.reject(error);
  },
);

const api_typebot = {
  axios: axiosInstance,
  get: (url, config = {}) => axiosInstance.get(url, config),
  post: (url, data = {}, config = {}) => axiosInstance.post(url, data, config),
  patch: (url, data = {}, config = {}) =>
    axiosInstance.patch(url, data, config),
  put: (url, data = {}, config = {}) =>
    axiosInstance.put(url, data, config),
  delete: (url, config = {}) => axiosInstance.delete(url, config),
};

export default api_typebot;
