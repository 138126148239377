import api from 'api/api';
import { useEffect, useState } from 'react';
import CheckTable from './components/CheckTable';


const Messages = () => {

  const [tableData, setTableData] = useState([]); //useState<SortingState>([{id: "time", desc: true}]);
  
  const columnsDataServices = [

    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Nome',
      accessor: 'name',
    },
    {
      Header: 'Data de envio',
      accessor: 'sendDate',
    },
    {
      Header: 'WhatsApp',
      accessor: 'whatsapp',
    },
    {
      Header: 'Mensagem',
      accessor: 'content',
    },
    {
      Header: 'Id',
      accessor: 'id',
    },
  ];

  useEffect(() => {
    api
      .get('Message')
      .then((response) => {
        console.log('dadosMsg: ', response.data[6]);
        const result = response.data.map((item) => {
          return {
            id: item._id,
            status: item?.status || '--//--',
            sendDate: item.createAt,
            whatsapp: item?.whatsapp || '--//--', //.customerId?.whatsapp || '--//--',
            content: item.content,
            name: item?.name || '--//--',
          };
        });

        // Ordena os resultados por data em ordem decrescente
        result.sort((a, b) => new Date(b.sendDate) - new Date(a.sendDate));

        setTableData(result);
      })
      .catch((error) => {
        console.error('Erro na requisição GET:', error);
      });
  }, []);


  // ----------------------------------------------------------------------------------------------------------------------------
  // MANIPULADORES DO CAMPO PESQUISA

  // Estado p/ o campo pesquisa
  const [searchTerm, setSearchTerm] = useState('');

  // Função para lidar com a mudança no campo de pesquisa
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filtro dos dados da tabela com base no termo de pesquisa
  const filteredTableData = tableData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );





  // ============================================================================================================================
  //return <CheckTable columnsData={columnsDataServices} tableData={tableData} />;
  return (
    <div>
      {/* Adicionado: campo de pesquisa */}
      {/*<input type="text" value={searchTerm} onChange={handleSearchChange} placeholder="Pesquisar..." />*/}

      <CheckTable
        columnsData={columnsDataServices}
        tableData={filteredTableData}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
      />
    </div>
  );
};

export default Messages;
