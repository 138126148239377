import api from 'api/api';
import { useEffect, useState } from 'react';
import CheckTable from './components/CheckTable';
import dayjs from 'dayjs';
import './css/darkmode.css';
import Filters from './components/filters';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from '@chakra-ui/react';


const CustomersCrud = () => {
  const [tableData, setTableData] = useState([]);
  const [carregando, setCarregando] = useState(true);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [planFilter, setPlanFilter] = useState('all');
  const [serviceFilter, setServiceFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [billingFilter, setBillingFilter] = useState('all');
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [statusClienteFilter, setStatusClienteFilter] = useState('all');
  const [visibleItemsCount, setVisibleItemsCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');


  const columnsDataServices = [
    {
      Header: 'Nome',
      accessor: 'name',
    },
    {
      Header: 'WhatsApp',
      accessor: 'whatsapp',
    },
    {
      Header: 'Plano',
      accessor: 'plan',
    },
    {
      Header: 'Serviço',
      accessor: 'service',
    },
    {
      Header: 'Categorias',
      accessor: 'categoryDynamic',
    },
    {
      Header: 'Estado venc.',
      accessor: 'validate',
    },
    {
      Header: 'Vencimento',
      accessor: 'endDate',
    },
    {
      Header: 'Fatura',
      accessor: 'invoice',
    },
    {
      Header: 'Ação',
      accessor: 'action',
    },
  ];


  // ----------------------------------------------------------------------------------------------------------------------------
  // MANIPULADORES DO CAMPO PESQUISA

  // Estado p/ o campo pesquisa
  const [searchTerm, setSearchTerm] = useState('');

  // Função para lidar com a mudança no campo de pesquisa
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filtro dos dados da tabela com base no termo de pesquisa
  const filteredTableData = tableData.filter((item) =>
    Object.values(item).some((value) => {
      if (!value) {
        return false;
      }
      return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
    })
  );
  //console.log('filteredTableData: ', filteredTableData);
  // Atualize o estado da quantidade de itens visíveis
  //console.log('filteredTableData.length: ', filteredTableData.length);
  //if (filteredTableData.length > 0) setVisibleItemsCount(filteredTableData.length);



  




  useEffect(() => {
    fetchCustomers(currentPage);
  }, [
    itemsPerPage,
    currentPage,
    planFilter,
    serviceFilter,
    statusFilter,
    billingFilter,
    categoriesFilter,
    statusClienteFilter,
    startDate, 
    endDate,
  ]);

  useEffect(() => {
    setVisibleItemsCount(filteredTableData.length);
  }, [filteredTableData]);

  const fetchCustomers = (page) => {
    /*const _categoriesFilter = JSON.stringify(categoriesFilter);
    console.log('_categoriesFilter(API):', _categoriesFilter);*/

    // ATIVA TELA DE CARREGAMENTO
    setCarregando(true);

    api
      .post(
        //`customer?page=${page}&size=${itemsPerPage}&plan=${planFilter}&service=${serviceFilter}&status=${statusFilter}&billing=${billingFilter}&search=${searchTerm}&categories=${ _categoriesFilter }`,
        'customer/all-customers', {
        page,
        size: itemsPerPage,
        plan: planFilter,
        service: serviceFilter,
        status: statusFilter,
        billing: billingFilter,
        search: searchTerm,
        categories: categoriesFilter,
        statusCustomer: statusClienteFilter,
        startDate,
        endDate,
      }
      )
      .then((response) => {
        const result = response.data.customers.map((item) => {
          return {
            name: item.name,
            id: item._id,
            whatsapp: item.whatsapp,
            plan: `${item.planId.name} (R$${parseFloat(
              item.planId.value.$numberDecimal,
            ).toFixed(2)})`,
            device: item.device,
            mac: item.mac,
            key: item.key,
            apps: item.apps,
            service: item.serviceId.name,
            invoice: item.invoice,
            login: item.login,
            password: item.password,
            planId: item.planId,
            serviceId: item.serviceId,
            comment: item.comment,
            endDate: item.validateDate.split('T')[0],
            sendNotificationOn: item.sendNotificationOn,
            validate: item.validateDate.split('T')[0],
            categoryDynamic: item.categoryDynamic,
            status: item.status,
            dynamicFields: item?.dynamicFields || { dynamicFields: [] },
          };
        });
        setTotalPages(Math.ceil(response.data.totalCount / itemsPerPage));
        setTableData(result);
        setCarregando(false);

        setTotalItemsCount(response.data.totalCount);

      })
      .catch((error) => {
        setCarregando(false);
        console.error('Erro na requisição GET:', error);
      });
  };
  return (
    <>
      {window.innerWidth <= 600 ? (
        <Accordion className="w-full" allowMultiple /*defaultIndex={[0]} allowToggle*/>
          <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">
            <AccordionButton className="flex justify-between button_default" /*className="bg-gray-200 p-2"*/>
              {/*<Box flex="1" textAlign="left" className="text-gray-700 font-semibold">
                Mostrar filtros
              </Box>*/}
              <span
                className="text-sm text-left pl-[30px] font-bold text-navy-900 dark:text-white"
                flex="1"
              //textAlign="left"
              >
                Mostrar filtros
              </span>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel /*pb={4} className="bg-white p-4"*/>
              <Filters
                setPlanFilter={setPlanFilter}
                setServiceFilter={setServiceFilter}
                setStatusFilter={setStatusFilter}
                setBillingFilter={setBillingFilter}
                setCategoriesFilter={setCategoriesFilter}
                setStatusClienteFilter={setStatusClienteFilter}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ) : (
        <Filters
          setPlanFilter={setPlanFilter}
          setServiceFilter={setServiceFilter}
          setStatusFilter={setStatusFilter}
          setBillingFilter={setBillingFilter}
          setCategoriesFilter={setCategoriesFilter}
          setStatusClienteFilter={setStatusClienteFilter}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      )}


      <CheckTable
        columnsData={columnsDataServices}
        //tableData={tableData}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        onPageChange={(newPage) => setCurrentPage(newPage)}
        fetchCustomers={fetchCustomers}

        tableData={filteredTableData}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}

        carregando={carregando}
        visibleItemsCount={visibleItemsCount}
        totalItemsCount={totalItemsCount}
      />
    </>
  );
};

export default CustomersCrud;
