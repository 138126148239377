import React, { useState, useEffect } from 'react';
import Card from 'components/card';
import { Bars } from 'react-loader-spinner';

const Widget = ({ title, fetchTransactionsBalance, }) => {

  const dataAtual = new Date();
  const [startDate, setStartDate] = useState(new Date(dataAtual.getFullYear()-1, dataAtual.getMonth() ).toISOString().slice(0, 7));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 7));


  // ---------------------------------------------------------------------------------------------------------------------------
  const handleFilterDateStartChange = (e) => {

    const periodoTransação = e.target.value;
    //console.log('periodoTransação: ', periodoTransação);
    setStartDate(periodoTransação);

    fetchTransactionsBalance(periodoTransação, endDate);

  };

  // ---------------------------------------------------------------------------------------------------------------------------
  const handleFilterDateEndChange = (e) => {

    const periodoTransação = e.target.value;
    //console.log('periodoTransação: ', periodoTransação);
    setEndDate(periodoTransação);

    fetchTransactionsBalance(startDate, periodoTransação);

  };


  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px]">

      <div className="h-50 w-auto _flex _flex-col justify-center">

        {<p className="font-dm text-center text-sm font-medium text-gray-600 mb-1">{title}</p>}


        <div className='grid grid-cols-1 gap-1 w-full px-3'>

          {/* ------------------------------- SELECT - TIPO DE INTERVALO PERIODO ------------------------------- */}
          {/*<div className=''>
            <select
              id="tipo-intervalo"
              class="_flex h-5 w-full text-center items-center justify-center rounded-xl border bg-white/0 px-3 text-sm outline-none dark:!border-white/10"
            //value={typeSelect}
            //onChange={(e) => setTypeSelect(e.target.value)}
            >
              {<option className="dark:bg-gray-700" value={'mensal'}>
                {'mensal'}
              </option>}
              {<option className="dark:bg-gray-700" value={'anual'}>
                {'anual'}
              </option>}
            </select>
          </div>*/}

          {/* ------------------------------- CAMPO DATA DE INICIO ------------------------------- */}
          <div className=''>
            {<span className="text-sm text-navy-700 dark:text-white font-bold">
              Inicio
            </span>}
            <input className="h-5 ml-3 text-center items-center justify-center rounded-xl border bg-white/0 px-3 text-sm outline-none  border-gray-200 dark:!border-white/10 dark:text-white"
              type="month"
              value={startDate}
              onChange={handleFilterDateStartChange}
            />
          </div>


          {/* ------------------------------- CAMPO DATA DE FIM ------------------------------- */}
          <div className=''>
            {<span className="text-sm text-navy-700 dark:text-white font-bold">
              Fim
            </span>}
            <input className="h-5 ml-6 text-center items-center justify-center rounded-xl border bg-white/0 px-3 text-sm outline-none  border-gray-200 dark:!border-white/10 dark:text-white"
              type="month"
              value={endDate}
              onChange={handleFilterDateEndChange}
            />
          </div>

        </div>

      </div>
    </Card>
  );
};

export default Widget;
