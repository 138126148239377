/* eslint-disable */
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DashIcon from '../../../components/icons/DashIcon';

export function SidebarLinks(props) {
  let location = useLocation();
  const { 
    routes,
    onClose,
  } = props;

  // Estado para controlar a visibilidade dos submenus
  const [openSubmenu, setOpenSubmenu] = useState(null);

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const toggleSubmenu = (index) => {
    if (openSubmenu === index) {
      setOpenSubmenu(null); // Fecha o submenu se já estiver aberto
    } else {
      setOpenSubmenu(index); // Abre o submenu clicado
    }
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      const hasSubmenu = route.children && route.children.length > 0;
      const isSubmenuOpen = openSubmenu === index;

      // Se o item tem submenus, renderiza como dropdown
      if (hasSubmenu) {
        return (
          <div key={index}>
            <div className="relative _mb-3 flex hover:cursor-pointer px-[8px]" onClick={() => toggleSubmenu(index)}>
              <li className="_my-[3px] flex cursor-pointer items-center px-8 py-[10px] border border-collapse border-gray-200 rounded-[10px] w-[275px] hover:bg-[#ffffff2b] dark:!border-white/10">
                <span className={`${activeRoute(route.path) === true ? 'font-bold text-brand-500 dark:text-white' : 'font-medium text-gray-600'}`}>
                  {route.icon ? route.icon : <DashIcon />}{' '}
                </span>
                <p className={`leading-1 ml-4 flex ${activeRoute(route.path) === true ? 'font-bold text-navy-700 dark:text-white' : 'font-medium text-gray-600'}`}>
                  {route.name}
                </p>
              </li>
            </div>
            {isSubmenuOpen && (
              <div className="submenu-container bg-[#00000017]">
                {route.children.map((subRoute, subIndex) => (
                  <Link 
                    key={subIndex} 
                    to={subRoute.layout + '/' + subRoute.path}
                    onClick={onClose}
                  >
                    <div className="relative ml-3 _mb-3 flex hover:cursor-pointer px-[8px]">
                      <li className="_my-[3px] flex cursor-pointer items-center px-8 py-[10px] rounded-[10px] w-[255px] hover:bg-[#ffffff2b]">
                        <span className={`${activeRoute(subRoute.path) === true ? 'font-bold text-brand-500 dark:text-white' : 'font-medium text-gray-600'}`}>
                          {subRoute.icon ? subRoute.icon : <DashIcon />}{' '}
                        </span>
                        <p className={`leading-1 ml-4 flex ${activeRoute(subRoute.path) === true ? 'font-bold text-navy-700 dark:text-white' : 'font-medium text-gray-600'}`}>
                          {subRoute.name}
                        </p>
                      </li>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        );
      } 
      else if(!route.hide) {
        // Se o item não tem submenus, renderiza como link normal
        return (
          <Link 
            key={index} 
            to={route.layout + '/' + route.path}
            onClick={onClose}
          >
            <div className="relative _mb-3 flex hover:cursor-pointer px-[8px]">
              <li className="_my-[3px] flex cursor-pointer items-center px-8 py-[10px] rounded-[10px] w-[275px] hover:bg-[#ffffff2b]">
                <span className={`${activeRoute(route.path) === true ? 'font-bold text-brand-500 dark:text-white' : 'font-medium text-gray-600'}`}>
                  {route.icon ? route.icon : <DashIcon />}{' '}
                </span>
                <p className={`leading-1 ml-4 flex ${activeRoute(route.path) === true ? 'font-bold text-navy-700 dark:text-white' : 'font-medium text-gray-600'}`}>
                  {route.name}
                </p>
              </li>
            </div>
          </Link>
        );
      }
    });
  };

  return (
    <ul className="mb-auto pt-1">
      {createLinks(routes)}
    </ul>
  );
}

export default SidebarLinks;
