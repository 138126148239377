// Custom components
import { useDisclosure } from '@chakra-ui/hooks';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import Card from 'components/card';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { MdOutlineRepeat } from 'react-icons/md';
import Toast from 'components/toast';
import Datepicker from 'react-tailwindcss-datepicker';
import api from '../../../../api/api';

import dayjs from 'dayjs';
import { IoMdInformationCircle } from "react-icons/io";

function RenovationModal(props) {
  const {
    id,
    whatsapp,
    name,
    service,
    open,
    close,
    serviceId,
    fetchCustomers,
    //currentPage,
    plan,
    planId,
    validateDate,
    fields,
  } = props;

  //console.log('plan: ', plan);
  //console.log('planId: ', planId);

  const [customerValidateDate, setCustomerValidateDate] = useState('');
  const [customerInvoice, setCustomerInvoice] = useState('payed');

  const { isOpen, onOpen, onClose } = useDisclosure();

  // ------------------------------------------------------------------------------------------------------------------------
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  };

  useEffect(() => {
    if (open) {
      onOpen();
    } else {
      onClose();
      close();
    }
  }, [open, onOpen, onClose]);

  const handleValidateDateChange = (newValue) => {
    setCustomerValidateDate(newValue);
  };

  const renovateCustomer = () => {
    let date = new Date();

    if (customerValidateDate.endDate) {
      date = new Date(customerValidateDate.endDate);
    } else {
      date.setDate(date.getDate() + 31);
    }

    api
      .patch('customer/' + id, {
        invoice: customerInvoice,
        validateDate: date,
      })
      .then((response) => {
        console.log(response.data);
        fetchCustomers();

        Toast.fire({
          icon: 'success',
          title: 'Seu cliente foi editado com sucesso!',
        });

        onClose();
        close();

        /*setTimeout(() => {
          window.location.reload();
        }, 1500);*/
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: 'Erro ao editar cliente, tente novamente mais tarde.',
        });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        //props.onClose();
        close();
      }}
      className="!z-[1010]"
    //size="full"
    >
      <ModalOverlay className="bg-[#000] !opacity-30" />
      <ModalContent className="!z-[1002] !m-auto !w-[inherit] min-w-auto !max-w-[85%] top-[2vh]  md:min-w-[700px] md:!w-max _md:h-[800px]"
        containerProps={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          display: 'grid',
          placeContent: 'center',
          zIndex: '5000 !important',
        }}
      >
        <ModalBody className='overflow-x-auto'>
          <Card extra="px-[30px] pt-[35px] pb-[40px] flex !z-[1004] max-h-[450px] overflow-y-auto  md:max-w-[700px] md:max-h-[600px]">
            <h1 className="mb-[60px] text-2xl font-bold flex">
              <MdOutlineRepeat /> Renovar registro
            </h1>

            <p className="mb-[20px] text-m font-bold hidden">
              Deixe em branco para renovação automática de um mês ou escolha uma
              data personalizada
            </p>

            <div className="grid grid-cols-2 gap-2 mb-4 md:grid-cols-4">
              <div className={`w-full`}>
                <label
                  for="invoice"
                  class="text-sm font-bold text-navy-700 dark:text-white"
                >
                  Cliente
                </label>
                <p
                  className='text-gray-800 dark:text-gray-500'
                >{name}</p>
              </div>
              <div className={`w-full`}>
                <label
                  for="invoice"
                  class="text-sm font-bold text-navy-700 dark:text-white"
                >
                  WhatsApp
                </label>
                <p
                  className='text-gray-800 dark:text-gray-500'
                >{whatsapp}</p>
              </div>

              <div className={`w-full`}>
                <label
                  for="invoice"
                  class="text-sm font-bold text-navy-700 dark:text-white"
                >
                  Serviço
                </label>
                <p
                  className='text-gray-800 dark:text-gray-500'
                >
                  {service.reduce(
                    (s, i) => (i.id == serviceId ? (s = i.name) : s),
                    '',
                  )}
                </p>
              </div>

              <div className={`w-full`}>
                <label
                  //for="invoice"
                  class="text-sm font-bold text-navy-700 dark:text-white"
                >
                  Plano
                </label>
                <p
                  className='text-gray-800 dark:text-gray-500'
                >
                  {plan.reduce(
                    (s, i) => (i.id == planId ? (s = i.name) : s),
                    '',
                  )}
                </p>
              </div>

              <div className={`w-full`}>
                <label
                  //for="invoice"
                  class="text-sm font-bold text-navy-700 dark:text-white"
                >
                  Data de Validade
                </label>
                <p
                  className='text-gray-800 dark:text-gray-500'
                >
                  {dayjs(
                    addDays(
                      new Date(
                        new Date(validateDate).toLocaleString('en', {
                          timeZone: 'America/Sao_Paulo',
                        }),
                      ),
                      1,
                    ),
                  ).format('DD/MM/YYYY')}
                </p>
              </div>


              {/* --------------------------------------------------------------------------------------------------------- */}
              {/* Mapeia os campos dinâmicos da matriz fields */}
              {fields.map((field, index) => (
                <div key={index} className={`w-full`}>

                  <label
                    for="invoice"
                    className="text-sm font-bold text-navy-700 dark:text-white"
                  >
                    {field.label}
                  </label>
                  <p
                    className='text-gray-800 dark:text-gray-500'
                  >
                    {field.value || '--//--'}
                  </p>

                </div>
              ))}

            </div>




            {/* ===============================    CAMPOS P/ INTERAÇÃO    ============================== */}
            <div className="mt-10 grid grid-cols-1 gap-2 md:grid-cols-2">
              <div className={`w-full`}>
                <label
                  for="invoice"
                  class="text-sm font-bold text-navy-700 dark:text-white"
                >
                  Fatura
                </label>
                <select
                  id="customer.invoice"
                  class=" mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                  value={customerInvoice}
                  onChange={(e) => setCustomerInvoice(e.target.value)}
                >
                  <option value="payed">Pago</option>
                  <option value="pending">Pendente</option>
                </select>
              </div>

              <div className={`w-full`}>
                <label
                  for="invoice"
                  class="text-sm font-bold text-navy-700 dark:text-white"
                >
                  Data personalizada
                </label>
                <div className='flex'>
                  <Datepicker
                    value={customerValidateDate}
                    startFrom={new Date(customerValidateDate.endDate)}
                    inputId={'customer.validateDate'}
                    onChange={handleValidateDateChange}
                    placeholder="Escolha a data"
                    inputClassName="mt-2 h-12 w-full rounded-xl border p-3 text-sm outline-none"
                    toggleClassName="absolute mt-5 ml-[-30px]"
                    asSingle={true}
                    displayFormat={'DD/MM/YYYY'}
                  />

                  <div
                    className={`mt-4 ml-1 text-[30px] text-gray-700 dark:text-gray-100`}
                    title='Deixe em branco para renovação automática de duração de um mês'
                  >
                    <IoMdInformationCircle />
                  </div>
                </div>

              </div>

            </div>



            {/* ------------------ BOTÕES "CANCELAR" E "RENOVAR" ---------------- */}
            <div className="mt-10 flex justify-end gap-2">
              <button
                onClick={() => {
                  onClose();
                  //props.onClose();
                  close();
                }}
                className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
              >
                Cancelar
              </button>
              <button
                onClick={renovateCustomer}
                className="linear rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
              >
                Renovar
              </button>
            </div>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RenovationModal;
